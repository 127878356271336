import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import classnames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { Event } from '../../../../../../types';
import { MobileDayCalendarView } from './MobileDayCalendarView';

interface IProps {
  events: Event[];
  today: Moment;
}

export function DesktopDayCalendarView({ events, today }: IProps) {
  const startDay = today.clone().startOf('day');
  const [selectedDay, setSelectedDay] = useState(startDay);
  const [selectedMonth, setSelectedMonth] = useState(
    today.clone().startOf('month'),
  );
  useEffect(() => {
    setSelectedDay(today);
  }, [today]);
  const day = selectedMonth.clone().subtract(selectedMonth.isoWeekday(), 'day');
  const daysMap = [...Array(42)].map(() => day.add(1, 'day').clone());

  let hours: number[] = [];

  return (
    <div className="isolate flex flex-auto overflow-hidden bg-white">
      <div className="flex flex-auto flex-col overflow-auto">
        <MobileDayCalendarView
          today={today}
          selectedDay={selectedDay}
          setSelectedDay={(day) => setSelectedDay(day)}
        />
        <div className="flex w-full flex-auto">
          <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
              style={{
                gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))',
              }}
            >
              <div className="row-end-1 h-7" />
              {[...new Array(24)].map((e, i) => (
                <React.Fragment key={i}>
                  <div>
                    <div className="-ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      {`${i}`.padStart(2, '0')}:00
                    </div>
                  </div>
                  <div />
                </React.Fragment>
              ))}
            </div>

            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
              style={{
                gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto',
              }}
            >
              {events
                .filter((event) => selectedDay.isSame(event.start.date, 'day'))
                .map((event, index) => {
                  const numCol = 1;
                  const hour = parseInt(moment(event.start.date).format('h'));
                  const numRow = 2 + hour * 12;
                  if (hours.includes(hour)) {
                    return <li></li>;
                  }
                  hours.push(hour);
                  return (
                    <li
                      key={index}
                      className={`col-start-${numCol} relative mt-px flex${day.isoWeekday()}`}
                      style={{ gridRow: `${numRow} / span 12` }}
                    >
                      <a
                        href="/"
                        className="bg-blue-50 hover:bg-blue-100 group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5"
                      >
                        <p className="text-blue-700 order-1 font-semibold">
                          {event.title}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          <time dateTime="2022-01-12T06:00">
                            {moment(event.start.date).format('h:mm A')}
                          </time>
                        </p>
                      </a>
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>
      </div>
      <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
        <div className="flex items-center text-center text-gray-900">
          <button
            onClick={() =>
              setSelectedMonth(selectedMonth.subtract(1, 'month').clone())
            }
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto font-semibold">
            {selectedMonth.format('MMMM')} {selectedMonth.format('YYYY')}
          </div>
          <button
            onClick={() =>
              setSelectedMonth(selectedMonth.add(1, 'month').clone())
            }
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="isolate mt-2 grid grid-cols-7 gap-px bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {daysMap.map((day, index) => (
            <button
              key={index}
              onClick={() => setSelectedDay(day)}
              type="button"
              className={classnames(
                selectedMonth.isSame(day, 'month')
                  ? 'bg-white text-gray-900'
                  : 'bg-gray-50 text-gray-400',
                'py-1.5 hover:bg-gray-100 focus:z-10',
              )}
            >
              <time
                dateTime={day.toISOString()}
                className={classnames(
                  day.isSame(selectedDay, 'day')
                    ? 'bg-gray-900 text-white'
                    : day.isSame(today, 'day') && 'font-semibold text-red-600',
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                )}
              >
                {day.format('D')}
              </time>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
