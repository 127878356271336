import { FormGroup } from '../../../../ui/form/FormGroup';
import { Select } from '../../../../ui/form/Select';
import { STAGES } from '../../../../constants';
import { Textarea } from '@tremor/react';
import { FormicProps } from '../../../../types';

interface CreateVacancyProps {
  formik: FormicProps;
  closeDialog: () => void;
  showSaveTemplate: () => void;
}

export const CreateVacancy = ({
  formik,
  closeDialog,
  showSaveTemplate,
}: CreateVacancyProps) => {
  const onSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <div className="mt-2 space-y-4">
        <FormGroup label="Select a stage" htmlFor="stage">
          <Select
            id="stage"
            name="stage"
            value={formik.values.stage}
            onChange={formik.handleChange}
            error={formik.errors.stage}
          >
            {STAGES.map((stage) => (
              <option key={stage.value} value={stage.value}>
                {stage.title}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup label="Message" htmlFor="text">
          <Textarea
            id="text"
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            placeholder="Enter text"
            className="mx-auto h-24 w-full"
          />
        </FormGroup>
      </div>

      <button
        onClick={() => {
          closeDialog();
          showSaveTemplate();
        }}
        className="mt-8 cursor-pointer font-semibold text-red-500"
      >
        Save as template
      </button>

      <div className="mb-6 mt-4 flex w-full gap-3">
        <button
          className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
          onClick={() => {
            closeDialog();
          }}
        >
          Cancel
        </button>
        <button
          className="w-full rounded-lg border border-[#D0D5DD] bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
          onClick={onSubmit}
          type="submit"
        >
          Send
        </button>
      </div>
    </>
  );
};
