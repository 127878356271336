import axios from 'axios';
import { Dialog } from 'evergreen-ui';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { EditUserIcon } from '../../../assets/EditUserIcon';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { editUser } from '../../../network';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Input } from '../../../ui/form/Input';
import { Select } from '../../../ui/form/Select';
import { useOffices, usePeople, useRoles, useTeams } from '../hooks';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetch(): void;
  user: any;
};

export const EditUserDialog = ({ isOpen, onClose, refetch, user }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { mutateAsync } = useMutation(editUser);
  const { data: role_data } = useRoles();
  const { data: team_data } = useTeams();
  const { data: office_data } = useOffices();
  const { data: people_data } = usePeople();

  const handleEditUser = async (id: number) => {
    setIsEditing(true);
    await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_HOST}/v1/people/${id}`,
    });
    setIsEditing(false);
    onClose();
    refetch();
  };

  const formik = useFormik({
    initialValues: {
      full_name: user?.full_name,
      role: user?.role_name || '',
      team: user?.team || '',
      office: user?.office || '',
      manager: '', //! api needs to return users manager
      phone: '', //! api needs to return phone number
    },
    enableReinitialize: true,
    onSubmit: async ({ ...restValues }) => {
      mutateAsync({ ...restValues });
      closeDialog();
    },
  });

  function closeDialog() {
    formik.resetForm();
    onClose();
  }

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={closeDialog}
      hasFooter={false}
      hasHeader={false}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] border border-gray-200 bg-white p-3 shadow-sm">
            <EditUserIcon />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">User Detail</div>
          <div className="text-sm font-normal text-[#475467]">
            Update the user's information and permissions as needed. Make sure
            all details are accurate before saving
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} className="space-y-3">
          <FormGroup label="Full Name" htmlFor="name-input">
            <Input
              id="name-input"
              type="text"
              name="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              placeholder="Enter name"
            />
          </FormGroup>
          <FormGroup label="Role within the company" htmlFor="role-input">
            <Select
              id="role-input"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              {role_data?.map((role: any, index: number) => (
                <option key={index} value={role.value}>
                  {role.title}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup label="Office location" htmlFor="office-input">
            <Select
              id="office-input"
              name="office"
              value={formik.values.office}
              onChange={formik.handleChange}
            >
              {office_data &&
                !office_data?.status &&
                office_data?.map((office: any, index: number) => (
                  <option key={index} value={office.id}>
                    {office.name}
                  </option>
                ))}
            </Select>
          </FormGroup>
          <FormGroup label="Select team" htmlFor="team-input">
            <Select
              id="team-input"
              name="team"
              value={formik.values.team}
              onChange={formik.handleChange}
            >
              {team_data &&
                !team_data?.status &&
                team_data?.map((team: any, index: number) => (
                  <option key={index} value={team.id}>
                    {team.name}
                  </option>
                ))}
            </Select>
          </FormGroup>
          <FormGroup label="Manager" htmlFor="manager-input">
            <Select
              id="manager-input"
              name="manager"
              value={formik.values.manager}
              onChange={formik.handleChange}
            >
              {people_data?.data?.map((person: any, index: number) => (
                <option key={index} value={person.id}>
                  {person.full_name}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup label="Phone" htmlFor="phone-input">
            <Input
              id="phone-input"
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </FormGroup>
        </form>

        <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-[#475467] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={() => handleEditUser(user?.id)}
            disabled={isEditing}
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};
