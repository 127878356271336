import { FormicProps } from '../../../../types';
import { Button, Card, Text, Title } from '@tremor/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { FormGroup } from '../../../../ui/form/FormGroup';
import { Select } from '../../../../ui/form/Select';
import { STAGES } from '../../../../constants';
import { Input } from '../../../../ui/form/Input';
import { MultiSelect, MultiSelectItem } from '@tremor/react';
import { Textarea } from '@tremor/react';

interface CreateVacancyProps {
  formik: FormicProps;
  closeDialog: () => void;
}

const templateData = [
  {
    name: 'Template Name',
    title: 'We are glad to invite you for an interview!',
    text: 'Lorem ipsum dolor sit amet consectetur. Quis amet lectus arcu tristique integer diam at ornare. Etiam id elit massa duis pellentesque eu sit arcu. Pulvinar sit massa ut porta purus amet augue tempus. Consectetur vel eget porttitor cursus ornare pellentesque libero.',
    from: 'johndoe@gmail.com',
  },
  {
    name: 'Template Name',
    title: 'We are glad to invite you for an interview!',
    text: 'Lorem ipsum dolor sit amet consectetur. Quis amet lectus arcu tristique integer diam at ornare. Etiam id elit massa duis pellentesque eu sit arcu. Pulvinar sit massa ut porta purus amet augue tempus. Consectetur vel eget porttitor cursus ornare pellentesque libero.',
    from: 'johndoe@gmail.com',
  },
  {
    name: 'Template Name',
    title: 'We are glad to invite you for an interview!',
    text: 'Lorem ipsum dolor sit amet consectetur. Quis amet lectus arcu tristique integer diam at ornare. Etiam id elit massa duis pellentesque eu sit arcu. Pulvinar sit massa ut porta purus amet augue tempus. Consectetur vel eget porttitor cursus ornare pellentesque libero.',
    from: 'johndoe@gmail.com',
  },
  {
    name: 'Template Name',
    title: 'We are glad to invite you for an interview!',
    text: 'Lorem ipsum dolor sit amet consectetur. Quis amet lectus arcu tristique integer diam at ornare. Etiam id elit massa duis pellentesque eu sit arcu. Pulvinar sit massa ut porta purus amet augue tempus. Consectetur vel eget porttitor cursus ornare pellentesque libero.',
    from: 'johndoe@gmail.com',
  },
];

export const Templates = ({ formik, closeDialog }: CreateVacancyProps) => {
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const onSubmit = () => {
    formik.handleSubmit();
  };

  const handleEmailFromChange = (selectedEmails: any) => {
    formik.setFieldValue('from', selectedEmails);
  };

  const emails = ['username@gmail.com', 'test@gmail.com', 'johndoe@gmail.com'];
  return (
    <>
      {isEditing ? (
        <div className="mt-4">
          <FormGroup label="Select a stage" htmlFor="stage">
            <Select
              id="stage"
              name="stage"
              value={formik.values.stage}
              onChange={formik.handleChange}
              error={formik.errors.stage}
            >
              {STAGES.map((stage) => (
                <option key={stage.value} value={stage.value}>
                  {stage.title}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup label="From" htmlFor="from">
            <MultiSelect
              defaultValue={[templateData[currentTemplateIndex].from]}
              onChange={handleEmailFromChange}
              name="from"
              id="from"
              error={!!formik.errors.from}
            >
              {emails.map((email) => (
                <MultiSelectItem key={email} value={email}>
                  {email}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </FormGroup>

          <FormGroup label="Subject" htmlFor="subject">
            <Input
              value={templateData[currentTemplateIndex].title}
              onChange={formik.handleChange}
              name="subject"
              id="subject"
              error={formik.errors.subject}
              placeholder="Enter text"
            />
          </FormGroup>

          <FormGroup label="Message" htmlFor="text">
            <Textarea
              id="text"
              name="text"
              defaultValue={templateData[currentTemplateIndex].text}
              onChange={formik.handleChange}
              placeholder="Enter text"
              className="mx-auto h-24 w-full"
            />
          </FormGroup>
        </div>
      ) : (
        <div className="mt-4 h-96 overflow-x-hidden overflow-y-scroll scroll-auto p-0">
          {templateData.map((templateData, index) => (
            <Card
              className={`m-4 p-4 ${
                currentTemplateIndex === index
                  ? 'border-2 border-orange-400 bg-orange-50'
                  : 'border-1 border-gray-300 bg-white'
              } relative w-fit rounded-md`}
            >
              <div className="flex">
                {currentTemplateIndex === index ? (
                  <input
                    name="group"
                    type="radio"
                    checked
                    className="form-radio my-auto h-4 w-4 border-gray-300 text-orange-400 checked:bg-orange-400 hover:bg-transparent focus:outline-none focus:ring-0 active:bg-transparent"
                    onChange={() => setCurrentTemplateIndex(index)}
                  />
                ) : (
                  <input
                    name="group"
                    type="radio"
                    className="form-radio my-auto h-4 w-4 border-gray-300 text-orange-400 checked:bg-orange-400 hover:bg-transparent focus:outline-none focus:ring-0 active:bg-transparent"
                    onChange={() => setCurrentTemplateIndex(index)}
                  />
                )}

                <Title className="my-auto ml-2 text-lg font-semibold">
                  {templateData.name}
                </Title>
                <div className="ml-auto flex space-x-2">
                  <Button
                    onClick={() => setIsEditing(true)}
                    icon={PencilIcon}
                    className="border-0 bg-transparent px-0 text-gray-500 !shadow-none hover:bg-transparent"
                  />
                  <Button
                    icon={TrashIcon}
                    className="m-0 border-0 bg-transparent pr-0 text-red-500 !shadow-none hover:bg-transparent"
                  />
                </div>
              </div>
              <Text className="mt-2 font-bold">{templateData.title}</Text>
              <Text className="mt-1 text-ellipsis text-gray-500">
                {templateData.text}
              </Text>
              <Text className="mt-4 font-semibold text-gray-700">
                From: <span className="font-normal">{templateData.from}</span>
              </Text>
            </Card>
          ))}
        </div>
      )}
      <div className="mt-2 space-y-4">
        <div className="mb-6 mt-4 flex w-full gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={closeDialog}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={onSubmit}
            type="submit"
          >
            {isEditing ? 'Save' : 'Send'}
          </button>
        </div>
      </div>
    </>
  );
};
