import { PhotoIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import React, { ChangeEvent, useContext, useState } from 'react';
import { OnboardingContext } from '../../context/onboarding';

interface DeploymentProps {
  onNext: () => void;
  onPrevious: () => void;
}

const Deployment: React.FC<DeploymentProps> = ({ onNext, onPrevious }) => {
  const [data, setData] = useState({
    timezone: '',
    logo: '',
    invoiceEmail: '',
    subdomain: '',
    allowance: {
      remoteWorking: '',
      unpaidLeave: '',
      paidLeave: '',
    },
  });

  const [fileDetails, setFileDetails] = useState<any>(undefined);
  const { previousPage, nextPage, storeValue } = useContext(OnboardingContext);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      if (file) {
        setFileDetails({
          name: file.name,
          uri: URL.createObjectURL(file),
          size: (file.size / (1024 * 1024)).toFixed(2), // Convert to MB and keep 2 decimal places
          raw: file,
        });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        timezone: '',
        logo: '',
        invoiceEmail: '',
        subdomain: '',
        allowance: {
          remoteWorking: '',
          unpaidLeave: '',
          paidLeave: '',
        },
      }}
      onSubmit={async (values, actions) => {
        storeValue({ ...values, logo: fileDetails.raw });
        nextPage();
      }}
    >
      <Form>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Company Details
              </h2>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              {/* Logo */}
              <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Logo
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    <p className="mb-6 text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                    {fileDetails?.name && (
                      <p className="text-xs leading-5 text-gray-600">
                        File uploaded: {fileDetails?.name} - Size:{' '}
                        {fileDetails?.size} KB
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Invoice Email */}
              <div className="col-span-full">
                <label
                  htmlFor="invoice-email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Invoice Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="invoice-email"
                    id="invoice-email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Subdomain */}
              <div className="col-span-full">
                <label
                  htmlFor="subdomain"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Subdomain
                </label>
                <div className="relative mt-2">
                  <input
                    type="text"
                    name="subdomain"
                    id="subdomain"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    .redstonehr.io
                  </span>
                </div>
              </div>

              {/* Timezone */}
              <div className="col-span-full">
                <label
                  htmlFor="timezone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Timezone
                </label>
                <div className="mt-2">
                  <select
                    id="timezone"
                    name="timezone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    {/* Populate with actual list of timezones */}
                    <option>UTC+0</option>
                    <option>UTC+1</option>
                    {/* ... */}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default Deployment;
