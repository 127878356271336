/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

export type BookTimeParams = {
  kind: string;
  start: Date;
  end: Date;
};

export type BookRecurringTimeAwayParams = {
  workingDays: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday'[];
  period: {
    value: number;
    type: 'weeks' | 'month';
    start: Date;
  };
};

async function queryTimeaway(params: any) {
  const { data } = await axios.post(`${API_HOST}/v1/timeaway/query`, params);
  return data;
}

async function bookTime(kind: string, start: any, end: any) {
  const { data } = await axios.put(`${API_HOST}/v1/timeaway`, {
    kind,
    start: {
      date: start.date,
      time: start.time,
    },
    end: {
      date: end.date,
      time: end.time,
    },
  });

  return data;
}

async function bookRecurringTimeAway(params: BookRecurringTimeAwayParams) {
  const { data } = await axios.post(
    `${API_HOST}/v1/timeaway`,
    {},
    {
      params: {
        recurring: true,
      },
    },
  );

  return data;
}

async function getTimeAway() {
  const { data } = await axios.get(`${API_HOST}/v1/timeaway`);
  return data;
}

export default {
  bookTime,
  bookRecurringTimeAway,
  getTimeAway,
};
