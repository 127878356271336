import { XMarkIcon } from '@heroicons/react/24/outline';
import { Checkbox, Dialog } from 'evergreen-ui';
import { KpiType } from '.';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  BarChart,
  LineChart,
  DonutChart,
  AreaChart,
  DateRangePicker,
  DateRangePickerValue,
  DateRangePickerItem,
  Legend,
} from '@tremor/react';
import { Select } from '../../ui/form/Select';
import { Switch } from '../../ui/Switch';
import { Input } from '../../ui/form/Input';
import moment from 'moment';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { addKpi } from '../../network';

type Props = {
  isOpen: boolean;
  onClose(): void;
  kpi: KpiType | null;
};

type ChartType = 'line' | 'bar' | 'donut' | 'area' | 'pie';

const chartTypes: ChartType[] = ['line', 'bar', 'donut', 'area', 'pie'];

const chartdata = [
  { date: '2024-07-01', value: 5, value2: 8, value3: 10 },
  { date: '2024-07-02', value: 3, value2: 9, value3: 12 },
  { date: '2024-07-03', value: 4, value2: 7, value3: 11 },
  { date: '2024-07-04', value: 6, value2: 9, value3: 9 },
  { date: '2024-07-05', value: 2, value2: 17, value3: 8 },
  { date: '2024-07-06', value: 7, value2: 5, value3: 15 },
  { date: '2024-07-07', value: 3, value2: 3, value3: 4 },
  { date: '2024-07-08', value: 5, value2: 1, value3: 6 },
  { date: '2024-07-09', value: 4, value2: 8, value3: 10 },
  { date: '2024-07-10', value: 6, value2: 10, value3: 12 },
  { date: '2024-07-11', value: 2, value2: 4, value3: 5 },
  { date: '2024-07-12', value: 3, value2: 6, value3: 8 },
  { date: '2024-07-13', value: 7, value2: 7, value3: 14 },
  { date: '2024-07-14', value: 5, value2: 12, value3: 13 },
  { date: '2024-07-15', value: 4, value2: 8, value3: 9 },
  { date: '2024-07-16', value: 6, value2: 9, value3: 15 },
  { date: '2024-07-17', value: 2, value2: 4, value3: 6 },
  { date: '2024-07-18', value: 3, value2: 6, value3: 7 },
  { date: '2024-07-19', value: 5, value2: 7, value3: 10 },
  { date: '2024-07-20', value: 4, value2: 4, value3: 8 },
  { date: '2024-07-21', value: 6, value2: 9, value3: 13 },
  { date: '2024-07-22', value: 2, value2: 10, value3: 6 },
  { date: '2024-07-23', value: 3, value2: 7, value3: 9 },
  { date: '2024-07-24', value: 7, value2: 8, value3: 15 },
  { date: '2024-07-25', value: 5, value2: 17, value3: 18 },
  { date: '2024-07-26', value: 4, value2: 7, value3: 11 },
  { date: '2024-07-27', value: 6, value2: 9, value3: 12 },
  { date: '2024-07-28', value: 2, value2: 1, value3: 4 },
  { date: '2024-07-29', value: 3, value2: 6, value3: 9 },
  { date: '2024-07-30', value: 5, value2: 7, value3: 11 },
  { date: '2024-07-31', value: 4, value2: 20, value3: 19 },
];

const dataFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

const filterDataByRange = (
  data: any,
  customRange: [string, string] | null = null,
): any => {
  if (customRange) {
    const [startDate, endDate] = customRange;
    return data.filter((d: any) =>
      moment(d.date).isBetween(moment(startDate), moment(endDate), null, '[]'),
    );
  }

  return data;
};

export const KpiModal = ({ isOpen, onClose, kpi }: Props) => {
  const [chartType, setChartType] = useState<ChartType>('bar');
  const { mutateAsync } = useMutation(addKpi);
  const [customRange, setCustomRange] = useState<[string, string]>();
  const [isShowLegend, setIsShowLegend] = useState<boolean>(true);
  const [xAxisLabelText, setXAxisLabelText] = useState<string>('Date');
  const [yAxisLabelText, setYAxisLabelText] = useState<string>('Value');
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: new Date(),
    to: new Date(),
  });

  const categories = useMemo(() => {
    const keys = new Set<string>();
    chartdata.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'date') {
          keys.add(key);
        }
      });
    });
    return Array.from(keys);
  }, []);

  const [currentCategories, setCurrentCategories] =
    useState<string[]>(categories);

  const formik = useFormik({
    initialValues: {
      range: {
        from: '',
        to: '',
      },
      xAxisLabel: 'Date',
      yAxisLabel: 'Value',
      type: 'bar',
      name: kpi?.name,
      id: kpi?.id,
      categories: [],
    },
    validationSchema: yup.object({
      range: yup.object({
        from: yup.string().required(),
        to: yup.string().required(),
      }),
      xAxisLabel: yup.string().required(),
      yAxisLabel: yup.string().required(),
      type: yup.string().required(),
      name: yup.string().required(),
      categories: yup.array().required(),
    }),

    onSubmit: async ({ ...restValues }) => {
      mutateAsync({ ...restValues });
      onClose();
    },
    enableReinitialize: true,
  });

  const handleChartTypeChange = (e: any) => {
    const value = e.target.value;
    setChartType(value as ChartType);
    formik.setFieldValue('type', value as ChartType);
  };

  const handleCloseForm = () => {
    formik.resetForm();
    onClose();
  };

  const handleDateRangeChange = (value: DateRangePickerValue) => {
    setDateRange(value);
    if (value.from && value.to) {
      const fromDate = moment(value.from).format('YYYY-MM-DD');
      const toDate = moment(value.to).format('YYYY-MM-DD');
      setCustomRange([fromDate, toDate]);
      formik.setFieldValue('range', { from: fromDate, to: toDate });
    }
  };

  const renderChart = (type: ChartType, data: any) => {
    const chartColors = ['sky', 'violet', 'lime', 'amber', 'violet'];

    switch (type) {
      case 'area':
        return (
          <AreaChart
            data={data}
            index="date"
            categories={currentCategories}
            colors={chartColors}
            valueFormatter={dataFormatter}
            yAxisWidth={48}
            showXAxis={isShowLegend}
            showYAxis={isShowLegend}
            showLegend={isShowLegend}
            xAxisLabel={xAxisLabelText}
            yAxisLabel={yAxisLabelText}
            showAnimation
          />
        );
      case 'line':
        return (
          <LineChart
            data={data}
            index="date"
            categories={currentCategories}
            colors={chartColors}
            valueFormatter={dataFormatter}
            yAxisWidth={48}
            showLegend={isShowLegend}
            showXAxis={isShowLegend}
            showYAxis={isShowLegend}
            xAxisLabel={xAxisLabelText}
            yAxisLabel={yAxisLabelText}
            showAnimation
            className="fill-inherit stroke-inherit"
          />
        );
      case 'bar':
        return (
          <BarChart
            data={data}
            index="date"
            categories={currentCategories}
            colors={chartColors}
            valueFormatter={dataFormatter}
            showLegend={isShowLegend}
            yAxisWidth={48}
            showXAxis={isShowLegend}
            showYAxis={isShowLegend}
            xAxisLabel={xAxisLabelText}
            yAxisLabel={yAxisLabelText}
            showAnimation
            className="bg-inherit fill-inherit stroke-inherit"
          />
        );
      case 'donut':
        return (
          <div className="flex flex-row items-center justify-center gap-8">
            {currentCategories.map((category) => {
              return (
                <DonutChart
                  key={category}
                  data={data}
                  category={category}
                  index="date"
                  valueFormatter={dataFormatter}
                  showAnimation
                  className="h-[200px] min-h-[20px] w-[200px] min-w-[20px] fill-current"
                />
              );
            })}
          </div>
        );
      case 'pie':
        return (
          <div className="flex flex-row items-center justify-center gap-8">
            {currentCategories.map((category) => {
              return (
                <DonutChart
                  key={category}
                  data={data}
                  category={category}
                  index="date"
                  variant="pie"
                  valueFormatter={dataFormatter}
                  showAnimation
                  className="h-[200px] min-h-[20px] w-[200px] min-w-[20px] fill-current"
                />
              );
            })}
          </div>
        );

      default:
        return null;
    }
  };

  const handleXAxisChange = (value: string) => {
    setXAxisLabelText(value);
    formik.setFieldValue('xAxisLabel', value);
  };

  const handleYAxisChange = (value: string) => {
    setYAxisLabelText(value);
    formik.setFieldValue('yAxisLabel', value);
  };

  const handleCheckboxChange = (category: string) => {
    const updatedCategories = currentCategories.includes(category)
      ? currentCategories.filter((cat) => cat !== category)
      : [...currentCategories, category];
    setCurrentCategories(updatedCategories);
    formik.setFieldValue('categories', updatedCategories);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        hasFooter={false}
        hasHeader={false}
        preventBodyScrolling
        width={800}
      >
        <div className="flex flex-col">
          <div className="mb-4 flex flex-row justify-between pt-6">
            <div className="text-xl font-bold text-gray-700">
              Add KPI to Dashboard
            </div>
            <XMarkIcon
              className="h-6 w-6 cursor-pointer"
              onClick={handleCloseForm}
            />
          </div>

          {kpi && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="text-xl font-medium text-gray-600">
                  {kpi.name}
                </div>
                <div className="text-sm font-normal text-gray-400">
                  {kpi.description}
                </div>
              </div>

              <div className="mb-4 flex flex-row items-start justify-between gap-4">
                <div className="">
                  <div className="flex items-start gap-2">
                    <Select value={chartType} onChange={handleChartTypeChange}>
                      {chartTypes.map((type) => (
                        <option key={type} value={type}>
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </option>
                      ))}
                    </Select>
                    <div className="mb-4 flex flex-col items-start gap-4">
                      <DateRangePicker
                        className="mx-auto max-w-md"
                        value={dateRange}
                        onValueChange={handleDateRangeChange}
                        selectPlaceholder="Select"
                        color="rose"
                      >
                        <DateRangePickerItem
                          key="7days"
                          value="7days"
                          from={moment().subtract(7, 'days').toDate()}
                        >
                          Last 7 days
                        </DateRangePickerItem>
                        <DateRangePickerItem
                          key="30days"
                          value="30days"
                          from={moment().subtract(30, 'days').toDate()}
                        >
                          Last 30 days
                        </DateRangePickerItem>
                      </DateRangePicker>
                    </div>
                  </div>

                  <Switch
                    value={isShowLegend}
                    label="Legend"
                    onChange={setIsShowLegend}
                  />
                </div>

                <div className="flex flex-row gap-3">
                  {categories.map((category) => (
                    <Checkbox
                      className="!mt-0 !pt-0"
                      checked={currentCategories.includes(category)}
                      onChange={() => handleCheckboxChange(category)}
                      label={category}
                      key={category}
                    />
                  ))}
                </div>
              </div>

              <div className="flex h-80 w-full items-center justify-center ">
                {renderChart(
                  chartType,
                  filterDataByRange(chartdata, customRange),
                )}
              </div>

              <div className="flex flex-row justify-center gap-8">
                <div className="flex w-full flex-row items-center gap-2">
                  <label
                    htmlFor="xAxis"
                    className="text-sm font-medium text-gray-500"
                  >
                    X Axis Label
                  </label>
                  <Input
                    id="xAxis"
                    value={xAxisLabelText}
                    onChange={(e) => handleXAxisChange(e.target.value)}
                  />
                </div>

                <div className="flex w-full flex-row items-center gap-2">
                  <label
                    htmlFor="yAxis"
                    className="text-sm font-medium text-gray-500"
                  >
                    Y Axis Label
                  </label>
                  <Input
                    id="yAxis"
                    value={yAxisLabelText}
                    onChange={(e) => handleYAxisChange(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
                <button
                  type="button"
                  className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
                  onClick={handleCloseForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={() => formik.handleSubmit()}
                  className="w-full rounded-lg border border-[#D0D5DD] bg-[#475467] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
                >
                  Add to Dashboard
                </button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </form>
  );
};
