import { Dialog } from 'evergreen-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { scheduleInterview } from '../../../../network';
import * as yup from 'yup';
import { FormGroup } from '../../../../ui/form/FormGroup';
import { Select } from '../../../../ui/form/Select';
import { TIME, WORKPLACE_TYPES } from '../../../../constants';
import { useToast } from '../../../../utils/useToast';
import { BriefcaseIcon } from '../../../../assets/BriefcaseIcon';

type Props = {
  isOpen: boolean;
  onClose(): void;
  user: any;
};

export const ScheduleInterviewDialog = ({ isOpen, onClose, user }: Props) => {
  const { mutateAsync } = useMutation(scheduleInterview);
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      date: '',
      time: 'On-site',
      user: user.id,
    },
    validationSchema: yup.object({
      date: yup.string().required(),
      time: yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit: async ({ ...restValues }) => {
      mutateAsync({ ...restValues });
      toast({
        variant: 'success',
        title: 'Interview successfully scheduled.',
      });
      closeDialog();
    },
  });

  const closeDialog = () => {
    formik.resetForm();
    onClose();
  };

  const onSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
      preventBodyScrolling
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] border border-gray-200 bg-[#FEE9E9] p-3 shadow-sm">
            <BriefcaseIcon />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={closeDialog} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">Schedule an interview</div>
        </div>

        <div className="space-y-4">
          <FormGroup label="Date" htmlFor="stage">
            <input
              type="date"
              name="date"
              id="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              className="w-full rounded-lg border border-gray-300 bg-white text-gray-500"
            />
          </FormGroup>

          <FormGroup label="Time" htmlFor="time">
            <Select>
              {TIME.map((type) => {
                return (
                  <option key={type.value} value={type.value}>
                    {type.value}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          <FormGroup label="Meeting Type" htmlFor="meetingType">
            <Select>
              {WORKPLACE_TYPES.map((type) => {
                return (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
        </div>

        <div className="mb-6 mt-8 flex w-full flex-row gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={closeDialog}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-red-500 bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={onSubmit}
            type="submit"
          >
            Send
          </button>
        </div>
      </div>
    </Dialog>
  );
};
