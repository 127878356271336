import moment, { Moment } from 'moment';
import classnames from 'classnames';

interface IProps {
  daysMap: Moment[];
}

export function MobileWeekCalendarView({ daysMap }: IProps) {
  return (
    <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
      {daysMap.map((day, index) => (
        <button
          key={index}
          type="button"
          className="flex flex-col items-center pb-3 pt-2"
        >
          {day.format('dddd').substring(0, 1)}
          <span
            className={classnames(
              moment().isSame(day, 'day')
                ? 'rounded-full bg-red-600 font-semibold text-white'
                : 'font-semibold text-gray-900',
              'mt-1 flex h-8 w-8 items-center justify-center',
            )}
          >
            {day.format('D')}
          </span>
        </button>
      ))}
    </div>
  );
}
