import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';

import { Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

import {
  CALENDAR_MODE__MONTH,
  CALENDAR_MODE__WEEK,
  CALENDAR_MODE__DAY,
} from '../../../../constants';
import { EventDialog } from './EventDialog';
import { Divider, Tab, TabGroup, TabList } from '@tremor/react';

interface IProps {
  today: Moment;
  mode: string;
  setMode(mode: string): void;
  onPrevious(): void;
  onToday(): void;
  onNext(): void;
  setPerson(person: any): void;
  setOffice(office: any): void;
  setIsMyEvents(checked: boolean): void;
}

type Location = {
  id: string;
  name: string;
};

export function CalendarHeader({
  today,
  mode,
  setMode,
  onPrevious,
  onToday,
  onNext,
  setPerson,
  setOffice,
}: IProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location>();

  const [selected, setSelected] = useState<any>(0);

  useEffect(() => {
    setPerson(selected);
    setOffice(currentLocation);
  }, [selected, currentLocation]);

  return (
    <header className="flex flex-col space-x-3 border-b border-gray-200 px-8 py-4 lg:flex-none">
      <div className="ml-4 flex justify-between">
        <h1 className="text-lg font-semibold text-gray-900">
          <time dateTime={today.format('YYYY-MM')} className="sm:hidden">
            {today.format('MMM YYYY')}
          </time>
          <time dateTime={today.format('YYYY-MM')} className="hidden sm:inline">
            {today.format('MMMM YYYY')}
          </time>
        </h1>
        <div className="md:ml-4 md:flex md:items-center">
          <TabGroup>
            <TabList
              variant="solid"
              color="orange"
              className="tremor-background-red border bg-white"
            >
              {[
                CALENDAR_MODE__MONTH,
                CALENDAR_MODE__WEEK,
                CALENDAR_MODE__DAY,
              ].map((mode) => (
                <Tab onClick={() => setMode(mode)} key={mode}>
                  {mode} view
                </Tab>
              ))}
            </TabList>
          </TabGroup>
        </div>
      </div>
      <Divider className="mx-0! my-4" />
      <div className="flex items-center gap-3">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          <button
            onClick={onPrevious}
            type="button"
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={onToday}
            type="button"
            className="hidden border-b border-t border-gray-300 bg-white px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
          >
            Today
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />

          <button
            onClick={onNext}
            type="button"
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <button
          className=" overflow-none ml-auto text-ellipsis whitespace-nowrap rounded-lg border border-red-500 bg-red-500 px-4 py-2 text-sm font-semibold text-white"
          onClick={() => setIsDialogOpen(true)}
        >
          Create event
        </button>
      </div>
      <EventDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </header>
  );
}
