export enum GlobalAction {
  ActivateNotification = 'ACTIVATE_NOTIFICATION',
  ResetNotification = 'RESET_NOTIFICATION',
}

type ActivateNotificationParam = {
  type: GlobalAction.ActivateNotification;
  value: string;
};

type ResetNotificationParams = {
  type: GlobalAction.ResetNotification;
  value: string;
};

type GlobalActionParams = ActivateNotificationParam | ResetNotificationParams;

type GlobalState = {
  notification: {
    auth: {
      login: boolean;
      reset: boolean;
    };
  };
};

export function GlobalReducer(state: GlobalState, action: GlobalActionParams) {
  switch (action.type) {
    case GlobalAction.ActivateNotification:
      return {
        ...state,
        notification: {
          ...state.notification,
          auth: { ...state.notification.auth, [action.value]: true },
        },
      };

    case GlobalAction.ResetNotification:
      return {
        ...state,
        notification: {
          ...state.notification,
          auth: { ...state.notification.auth, [action.value]: false },
        },
      };
  }
}
