import { useCallback, useEffect, useMemo } from 'react';

type Props = {
  element: HTMLElement | null;
  isEnabled: boolean;
  callback(): void;
  options?: {
    ignoreSelectors: string[];
  };
};

export function useOutsideClickListener({
  element,
  isEnabled,
  callback,
  options,
}: Props) {
  const { ignoreSelectors = [] } = options || {};

  const outsideClickListener = useMemo(
    () => (event: MouseEvent) => {
      const target = event.target as Element;
      const shouldIgnore = ignoreSelectors.some((selector) =>
        target.closest(selector),
      );

      if (element && !element.contains(target) && !shouldIgnore) {
        callback();
      }
    },
    [element, callback, ignoreSelectors],
  );

  const listenOutsideClick = useCallback(() => {
    document.addEventListener('click', outsideClickListener, true);
  }, [outsideClickListener]);

  const unlistenOutsideClick = useCallback(() => {
    document.removeEventListener('click', outsideClickListener, true);
  }, [outsideClickListener]);

  useEffect(() => {
    return () => {
      unlistenOutsideClick();
    };
  }, [unlistenOutsideClick]);

  useEffect(() => {
    if (isEnabled && element) {
      listenOutsideClick();
    }
    return () => {
      unlistenOutsideClick();
    };
  }, [isEnabled, element, listenOutsideClick, unlistenOutsideClick]);
}
