import { useState } from 'react';
import classNames from 'classnames';

import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { ThirdStep } from './ThirdStep';
import { FourthStep } from './FourthStep';
import { FifthStep } from './FifthStep';
import { FinalStep } from './FinalStep';

import { OnboardingFormData } from '../../../types';
import { OnboardingSteps } from '../../../enum';

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

type Props = {
  setCurrentFormProps: (data: FormType) => void;
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
};

export function PersonalInfo({
  setCurrentFormProps,
  setCurrentFormData,
  formData,
}: Props) {
  const [currentPersonalInfoForm, setCurrentPersonalInfoForm] = useState(
    OnboardingSteps.FirstStep,
  );

  const backgroundClass = () => {
    switch (currentPersonalInfoForm) {
      case OnboardingSteps.FirstStep:
        return 'md:bg-form-image1';
      case OnboardingSteps.SecondStep:
        return 'md:bg-form-image2';
      // case OnboardingSteps.ThirdStep:
      //   return 'md:bg-form-image3';
      // case OnboardingSteps.FourthStep:
      //   return 'md:bg-form-image3';
      // case OnboardingSteps.FifthStep:
      //   return 'md:bg-form-image4';
      case OnboardingSteps.FinalStep:
        return 'md:bg-form-image5';
      default:
        return '';
    }
  };

  const renderForm = () => {
    console.log(currentPersonalInfoForm);
    switch (currentPersonalInfoForm) {
      case OnboardingSteps.FirstStep:
        return (
          <FirstStep
            setCurrentForm={setCurrentPersonalInfoForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
            setCurrentFormProps={setCurrentFormProps}
          />
        );
      case OnboardingSteps.SecondStep:
        return (
          <SecondStep
            setCurrentForm={setCurrentPersonalInfoForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      // case OnboardingSteps.ThirdStep:
      //   return (
      //     <ThirdStep
      //       setCurrentForm={setCurrentPersonalInfoForm}
      //       setCurrentFormData={setCurrentFormData}
      //       formData={formData}
      //     />
      //   );
      // case OnboardingSteps.FourthStep:
      //   return (
      //     <FourthStep
      //       setCurrentForm={setCurrentPersonalInfoForm}
      //       formData={formData}
      //       setCurrentFormProps={setCurrentFormProps}
      //     />
      //   );
      //   case OnboardingSteps.FifthStep:
      //     return (
      //       <FifthStep
      //         setCurrentForm={setCurrentPersonalInfoForm}
      //         formik={formik}
      //         findError={findError}
      //       />
      //     );
      case OnboardingSteps.FinalStep:
        return <FinalStep />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames(
        'flex h-screen justify-center bg-none bg-left sm:items-center',
        backgroundClass(),
      )}
    >
      <div className="flex w-screen flex-col lg:flex-row">
        <div className="flex h-full flex-col justify-center  bg-white  lg:absolute lg:right-0 lg:top-0 lg:w-1/2 lg:rounded-bl-[60px] lg:rounded-tl-[60px]">
          {renderForm()}
        </div>
      </div>
    </div>
  );
}
