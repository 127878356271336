import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useTeams } from './hooks';
import { TrashIcon } from '../../assets/TrashIcon';
import { EditIcon } from '../../assets/EditIcon';
import { useQuery } from 'react-query';
import { Spinner } from '../../ui/Spinner';
import { Pagination } from '../../ui/component/pagination';
import { DeletePeopleDialog } from './Dialogs/DeletePeopleDialog';
import { AddUserDialog } from './Dialogs/AddUserDialog';
import { EditUserDialog } from './Dialogs/EditUserDialog';
import { CreateTeamDialog } from './Dialogs/CreateTeamDialog';
import { Loader } from '../../ui/Loader';

export function PeopleRoute() {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCreateTeamDialogOpen, setIsCreateTeamDialogOpen] =
    useState<boolean>(false);

  const limit = 20;
  const [currentTeam, setCurrentTeam] = useState<number>(0);
  const { data: team_data } = useTeams();
  console.log(team_data);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentUser, setCurrentUser] = useState<any>();

  const { data: response = [], refetch } = useQuery({
    queryKey: [
      'people',
      { team: currentTeam, page: currentPage, limit: limit },
    ],
    queryFn: async ({ queryKey }) => {
      setIsLoading(true);
      console.log('1');
      const { data = [] } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/v1/people`,
        params: {
          page: currentPage,
          limit: limit,
          team: currentTeam,
        },
      });
      setIsLoading(false);

      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  useEffect(() => {
    setCurrentPage(0);
  }, [currentTeam]);

  const handleTeamChange = (team: number) => {
    setCurrentTeam(team);
    refetch();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    refetch();
  };

  const handleDeleteUser = async (user: any) => {
    setCurrentUser(user);
    setIsDeleteDialogOpen(true);
  };

  const handleEditUser = async (user: any) => {
    setCurrentUser(user);
    setIsEditDialogOpen(true);
  };

  useEffect(() => {
    refetch();
  }, []);

  console.log(team_data);

  return (
    <div>
      <AddUserDialog
        isOpen={isAddDialogOpen}
        onClose={() => {
          setIsAddDialogOpen(false);
        }}
      />

      <DeletePeopleDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        refetch={refetch}
        user={currentUser}
      />

      <EditUserDialog
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        refetch={refetch}
        user={currentUser}
      />

      <CreateTeamDialog
        isOpen={isCreateTeamDialogOpen}
        onClose={() => setIsCreateTeamDialogOpen(false)}
      />

      <div className="flex flex-col rounded-lg  bg-white shadow ring-1 ring-black ring-opacity-5">
        <div className="border-b border-gray-200">
          <div className="flex flex-row items-center justify-between px-4 py-5 md:px-6">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">People</h1>
              <p className="mt-2 text-sm text-gray-600">
                A list of all the users in your company including their name,
                title, email and role.
              </p>
            </div>

            <div className="flex flex-row items-center gap-4">
              <button
                onClick={() => setIsCreateTeamDialogOpen(true)}
                className="rounded-lg bg-[#EAECF0] px-4 py-[10px] text-sm font-semibold text-[#344054]"
              >
                Create Team
              </button>
              <button
                className="rounded-lg bg-red-500 px-4 py-[10px] text-sm font-semibold text-[#EAECF0]"
                onClick={() => setIsAddDialogOpen(true)}
              >
                <div className="flex flex-row items-center gap-2 ">
                  <PlusIcon className="h-5 w-5" />
                  <div className="text-white">Add User</div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="px-8 ">
          <div className="cursor-pointer border-gray-200 text-center text-sm font-medium text-gray-500">
            <ul className="flex w-full overflow-x-scroll">
              <li
                key={0}
                className={`${
                  currentTeam === 0
                    ? 'border-gray-700 text-gray-700'
                    : 'border-gray-200'
                } flex-shrink-0 border-b-2  transition-colors duration-300 ease-in-out hover:border-gray-600 hover:text-gray-600`}
                onClick={() => handleTeamChange(0)}
              >
                <div className="inline-block rounded-t-lg border-transparent p-4">
                  All people
                </div>
              </li>
              {team_data &&
                !team_data?.status &&
                team_data?.map((team: { id: number; name: string }) => {
                  return (
                    <li
                      key={team.id}
                      className={`${
                        currentTeam === team.id
                          ? 'border-gray-700 text-gray-700'
                          : 'border-gray-200'
                      } flex-shrink-0 border-b-2  transition-colors duration-300 ease-in-out hover:border-gray-600 hover:text-gray-600`}
                      onClick={() => handleTeamChange(team.id)}
                    >
                      <div className="inline-block rounded-t-lg  border-transparent p-4">
                        {team.name}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        {isLoading ? (
          <Spinner className="my-10" />
        ) : (
          <table className="min-w-full">
            <thead className="border-b border-gray-200">
              <tr>
                <th
                  scope="col"
                  className="py-3 pl-4 pr-3 text-left text-xs font-medium text-gray-600 sm:pl-6"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Office
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-600"
                >
                  Teams
                </th>
                {/* <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-gray-600"
              ></th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {response?.data &&
                response?.data.map((user: any) => (
                  <tr key={user.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.safe_profile_picture_url}
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium text-gray-900">
                            {user.full_name}
                          </div>
                          <div className="text-gray-500">{user.role_name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-fit rounded-2xl bg-[#F9FAFB] py-[2px] pl-1.5 pr-2 text-[#344054]">
                        <div className="flex items-center gap-1">
                          <div className="h-2 w-2 rounded-full bg-[#667085]"></div>
                          {user.status_now}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {user.office_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-fit rounded-2xl bg-[#F9FAFB] py-[2px] pl-1.5 pr-2 text-[#344054]">
                        {user.team}
                      </div>
                    </td>
                    <td className="w-[120px] whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                      <div className="flex items-center gap-4">
                        <div onClick={() => handleDeleteUser(user)}>
                          <TrashIcon className="cursor-pointer" />
                        </div>
                        <div onClick={() => handleEditUser(user)}>
                          <EditIcon className="cursor-pointer" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!isLoading && (
          <Pagination
            limit={limit}
            page={currentPage}
            count={response?.count}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
