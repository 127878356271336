import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { PEOPLE_SEX } from '../../../constants';
import { FormGroup } from '../../../ui/form/FormGroup';
import { Select } from '../../../ui/form/Select';
import { Input } from '../../../ui/form/Input';
import { useRoles, useTeams, useOffices, usePeople } from '../hooks';
import { createUser } from '../../../network';
import { useState } from 'react';
import { Dialog } from 'evergreen-ui';
import { AddUserIcon } from '../../../assets/AddUserIcon';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useToast } from '../../../utils/useToast';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export function AddUserDialog({ isOpen, onClose }: Props) {
  const { mutateAsync } = useMutation(createUser);
  const { data: role_data } = useRoles();
  const { data: team_data } = useTeams();
  const { data: office_data } = useOffices();
  const { data: people_data } = usePeople();
  const [secondStep, setSecondStep] = useState<boolean>(false);
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      sex: 'SEX_MALE',
      email: '',
      role: 'SOFTWARE_ENGINEER',
      team: '13',
      office: 'Nantwich',
      manager: '',
      phone: '',
      teamAssignment: '',
      paidHoliday: 10,
      unpaidHoliday: 10,
      remoteWorkingPerWeek: 2,
      paidSickLeavePerYear: 30,
    },
    onSubmit: async ({ ...restValues }) => {
      mutateAsync({ ...restValues });
      toast({
        variant: 'success',
        title: 'User successfully added.',
      });
      closeDialog();
    },
  });

  function closeDialog() {
    formik.resetForm();
    onClose();
    setSecondStep(false);
  }

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={closeDialog}
        hasFooter={false}
        hasHeader={false}
        preventBodyScrolling
      >
        <form className="mb-4" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col">
            <div className="mb-4 flex flex-row justify-between pt-6">
              <div className="rounded-[10px] border border-gray-200 bg-white p-3 shadow-sm">
                <AddUserIcon />
              </div>
              <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
            </div>

            <div className=" flex flex-col gap-1">
              <div className="text-lg font-semibold">Add User</div>
              <div className="text-sm font-normal text-[#475467]">
                Your new project has been created. Invite colleagues to
                collaborate on this project.
              </div>
            </div>

            <div className="my-6 flex flex-row items-center justify-center gap-3">
              {!secondStep ? (
                <div className="rounded-full bg-[#344054] p-3 text-white">
                  <div className="h-5 w-5  text-center text-sm font-medium ">
                    1
                  </div>
                </div>
              ) : (
                <div className="rounded-full bg-gray-200 p-3">
                  <CheckIcon className="h-5 w-5 " />
                </div>
              )}

              <div className="h-[1px] bg-gray-200 px-8"></div>

              <div className="rounded-full bg-[#344054] p-3 text-white">
                <div className="h-5 w-5  text-center text-sm font-medium ">
                  2
                </div>
              </div>
            </div>

            <div className="space-y-3">
              {!secondStep ? (
                <>
                  <FormGroup label="Full Name" htmlFor="name-input">
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      error={findError('name')}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      placeholder="Enter name"
                    />
                  </FormGroup>
                  <FormGroup label="Select gender" htmlFor="sex-input">
                    <Select
                      id="sex-input"
                      name="sex"
                      value={formik.values.sex}
                      onChange={formik.handleChange}
                    >
                      {PEOPLE_SEX.map((param, index) => (
                        <option key={index} value={param.value}>
                          {param.title}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup label="Email Address" htmlFor="email-input">
                    <Input
                      id="email-input"
                      type="text"
                      name="email"
                      error={findError('email')}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="you@example.com"
                    />
                  </FormGroup>
                  <FormGroup
                    label="Role within the company"
                    htmlFor="role-input"
                  >
                    <Select
                      id="role-input"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                    >
                      {role_data?.map((role: any, index: number) => (
                        <option key={index} value={role.value}>
                          {role.title}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup
                    label="Team assignment"
                    htmlFor="team-assignment-input"
                  >
                    <Input
                      id="team-assignment-input"
                      type="text"
                      name="team-assignment"
                      error={findError('team')}
                      value={formik.values.teamAssignment}
                      onChange={formik.handleChange}
                      placeholder="Enter assignment"
                    />
                  </FormGroup>

                  <FormGroup label="Office location" htmlFor="office-input">
                    <Select
                      id="office-input"
                      name="office"
                      value={formik.values.office}
                      onChange={formik.handleChange}
                    >
                      {office_data &&
                        !office_data?.status &&
                        office_data?.map((office: any, index: number) => (
                          <option key={index} value={office.id}>
                            {office.name}
                          </option>
                        ))}
                    </Select>
                  </FormGroup>
                  <FormGroup label="Select team" htmlFor="team-input">
                    <Select
                      id="team-input"
                      name="team"
                      value={formik.values.team}
                      onChange={formik.handleChange}
                    >
                      {team_data &&
                        !team_data?.status &&
                        team_data?.map((team: any, index: number) => (
                          <option key={index} value={team.id}>
                            {team.name}
                          </option>
                        ))}
                    </Select>
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup label="Manager" htmlFor="manager-input">
                    <Select
                      id="manager-input"
                      name="manager"
                      value={formik.values.manager}
                      onChange={formik.handleChange}
                    >
                      {people_data?.data?.map((person: any, index: number) => (
                        <option key={index} value={person.id}>
                          {person.full_name}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>
                  <FormGroup label="Phone" htmlFor="phone-input">
                    <Input
                      id="phone-input"
                      type="text"
                      name="phone"
                      error={findError('phone')}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                  <FormGroup label="Paid Holiday" htmlFor="paid-holiday">
                    <Input
                      id="paid-holiday"
                      name="paidHoliday"
                      value={formik.values.paidHoliday}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                    />
                  </FormGroup>

                  <FormGroup label="Unpaid Holiday" htmlFor="unpaid-holiday">
                    <Input
                      id="unpaid-holiday"
                      name="unpaidHoliday"
                      value={formik.values.unpaidHoliday}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                    />
                  </FormGroup>

                  <FormGroup
                    label="Remote Working Per Week"
                    htmlFor="remote-working-per-week"
                  >
                    <Input
                      id="remote-working-per-week"
                      name="remoteWorkingPerWeek"
                      value={formik.values.remoteWorkingPerWeek}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                    />
                  </FormGroup>

                  <FormGroup
                    label="Paid Sick Leave Per Year"
                    htmlFor="paid-sick-leave-per-year"
                  >
                    <Input
                      id="paid-sick-leave-per-year"
                      name="paidSickLeavePerYear"
                      value={formik.values.paidSickLeavePerYear}
                      onChange={formik.handleChange}
                      type="number"
                      min={0}
                      max={365}
                    />
                  </FormGroup>
                </>
              )}
            </div>

            <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
              <button
                type="button"
                className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
                onClick={onClose}
              >
                Cancel
              </button>
              {secondStep ? (
                <button
                  type="button"
                  onClick={() => formik.handleSubmit()}
                  className="w-full rounded-lg border border-[#D0D5DD] bg-[#475467] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
                >
                  Add
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setSecondStep(true)}
                  className="w-full rounded-lg border border-[#D0D5DD] bg-[#475467] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
