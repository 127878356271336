import { Moment } from 'moment';

import { Event } from '../../../../../types';
import { DesktopMonthCalendarView } from './month/DesktopMonthCalendarView';
import { DesktopDayCalendarView } from './day/DesktopDayCalendarView';
import { DesktopWeekCalendarView } from './week/DesktopWeekCalendarView';
import {
  CALENDAR_MODE__MONTH,
  CALENDAR_MODE__WEEK,
  CALENDAR_MODE__DAY,
} from '../../../../../constants';

interface IProps {
  mode: string;
  events: Event[];
  today: Moment;
}

export function ModesManager({ mode, ...restProps }: IProps) {
  switch (mode) {
    case CALENDAR_MODE__MONTH:
      return <DesktopMonthCalendarView {...restProps} />;

    case CALENDAR_MODE__WEEK:
      return <DesktopWeekCalendarView {...restProps} />;

    case CALENDAR_MODE__DAY:
      return <DesktopDayCalendarView {...restProps} />;

    default:
      return null;
  }
}
