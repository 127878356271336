import { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classnames from 'classnames';

import { useOutsideClickListener } from '../hooks/useOutsideClickListener';

export function Popover({
  refElement,
  isOpen,
  onClose,
  inPortal = true,
  className,
  options,
  outsideClickOptions,
  children,
}: {
  refElement: null | HTMLElement;
  isOpen: boolean;
  onClose(): void;
  inPortal?: boolean;
  className?: string;
  options?: Parameters<typeof usePopper>[2];
  outsideClickOptions?: Parameters<
    typeof useOutsideClickListener
  >[number]['options'];
  children: ReactNode;
}) {
  const [popperElement, setPopperElement] = useState<null | HTMLElement>(null);
  const { styles, attributes } = usePopper(refElement, popperElement, options);

  useOutsideClickListener({
    element: popperElement,
    isEnabled: isOpen,
    callback: onClose,
    options: outsideClickOptions,
  });

  const content = (
    <>
      {isOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={classnames(
            'z-[100] min-w-[250px] rounded-lg bg-white px-4 py-3 text-sm shadow-lg ring-1 ring-black ring-opacity-5',
            className,
          )}
          onClick={(event) => event.stopPropagation()}
        >
          {children}
        </div>
      )}
    </>
  );

  return inPortal ? createPortal(content, document.body) : content;
}
