import { VacancyItem } from './VacancyItem';
import { Spinner } from '../../../../ui/Spinner';
import { VacancyType } from '../../../../mirage/types';

export const VacanciesList = ({ vacancies }: { vacancies: VacancyType[] }) => {
  return (
    <div className="flex w-full flex-col gap-3 p-6">
      {vacancies ? (
        vacancies.map((vacancy, index) => {
          return <VacancyItem key={index} vacancy={vacancy} />;
        })
      ) : (
        <Spinner />
      )}
    </div>
  );
};
