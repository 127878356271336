import { Spinner } from '../../../../ui/Spinner';
import { CandidateType } from '../../../../mirage/types';
import { CandidateItem } from './CandidateItem';
import { Select, SelectItem } from '@tremor/react';

export const CandidateList = ({
  candidates,
  showUser,
}: {
  candidates: CandidateType[];
  showUser: (candidate: CandidateType) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-3 px-6  pb-6">
      <p>Showing 1-10 results out of total 141 candidates</p>
      <div className="flex justify-between">
        <Select className="w-[22%]" defaultValue="1">
          <SelectItem value="1">Date of publication</SelectItem>
          <SelectItem value="2">Option Two</SelectItem>
          <SelectItem value="3">Option Three</SelectItem>
        </Select>
        <Select className="w-[22%]" defaultValue="1">
          <SelectItem value="1">Category</SelectItem>
          <SelectItem value="2">Option Two</SelectItem>
          <SelectItem value="3">Option Three</SelectItem>
        </Select>
        <Select className="w-[22%]" defaultValue="1">
          <SelectItem value="1">City</SelectItem>
          <SelectItem value="2">Option Two</SelectItem>
          <SelectItem value="3">Option Three</SelectItem>
        </Select>
        <Select className="w-[22%]" defaultValue="1">
          <SelectItem value="1">Employment type</SelectItem>
          <SelectItem value="2">Option Two</SelectItem>
          <SelectItem value="3">Option Three</SelectItem>
        </Select>
      </div>
      {candidates ? (
        candidates.map((candidate, index) => {
          return (
            <CandidateItem
              showUser={showUser}
              key={index}
              candidate={candidate}
            />
          );
        })
      ) : (
        <Spinner />
      )}
    </div>
  );
};
