import { TextareaHTMLAttributes } from 'react';

import { CommonInputProps, BaseInput } from './BaseInput';

type Props = CommonInputProps & TextareaHTMLAttributes<HTMLTextAreaElement>;

export function Textarea({ error, ...restProps }: Props) {
  return (
    <BaseInput error={error}>
      {(baseProps) => <textarea {...baseProps} {...restProps} />}
    </BaseInput>
  );
}
