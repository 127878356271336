import { useEffect, useState } from 'react';

export function useFocusRemover() {
  const [focusedElement, setFocusedElement] = useState<null | HTMLElement>(
    null,
  );

  useEffect(() => {
    if (focusedElement) {
      setTimeout(() => focusedElement.blur());
    }
  }, [focusedElement]);

  return { setFocusedElement };
}
