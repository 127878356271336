import { CandidateType } from '../../../../mirage/types';
import moment from 'moment';
import { Pill } from 'evergreen-ui';
import { DescriptionExpandable } from './DescriptionExpandable';
import { useToast } from '../../../../utils/useToast';
import { useState } from 'react';
import { OfferVacancyDialog } from '../Dialogs/OfferVacancyDialog';
import { SaveTemplate } from '../Dialogs/SaveTemplate';

export const CandidateItem = ({
  candidate,
  showUser,
}: {
  candidate: CandidateType;
  showUser: (candidate: CandidateType) => void;
}) => {
  const { toast } = useToast();
  const [isOfferVacancyShow, setIsOfferVacancyShow] = useState<boolean>(false);
  const [isSaveTemplateShow, setIsSaveTemplateShow] = useState<boolean>(false);
  let currentDate = moment();

  const showSaveTemplate = () => {
    setIsSaveTemplateShow(true);
  };

  return (
    <>
      <OfferVacancyDialog
        isOpen={isOfferVacancyShow}
        onClose={() => {
          setIsOfferVacancyShow(false);
        }}
        showSaveTemplate={showSaveTemplate}
      />
      <SaveTemplate
        isOpen={isSaveTemplateShow}
        onClose={() => setIsSaveTemplateShow(false)}
      />
      <div className="flex flex-row justify-between gap-8 rounded-lg bg-white p-6 shadow ring-1 ring-black/5">
        <div className="flex w-full flex-col gap-3">
          {currentDate.diff(moment(candidate.date), 'days') < 3 && (
            <Pill className="w-fit" color="green">
              <p className="text-xs font-medium">New</p>
            </Pill>
          )}

          <div className="flex flex-col gap-3">
            <button
              rel="noreferrer"
              onClick={() => showUser(candidate)}
              className="text-left text-xl font-semibold text-gray-900"
            >
              {candidate.name}
            </button>
            <div className="text-base font-normal text-gray-500">
              {candidate.description.length > 200 ? (
                <DescriptionExpandable description={candidate.description} />
              ) : (
                candidate.description
              )}
            </div>
            <div>
              <p>
                Location:{' '}
                <span className="font-medium">{candidate.location}</span>
              </p>
              <p>
                Department:{' '}
                <span className="font-medium">{candidate.department}</span>
              </p>
            </div>

            <div className="text-base font-normal text-gray-500">
              {moment(candidate.date).fromNow()}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-3 self-start ">
          <button
            onClick={() =>
              toast({
                variant: 'success',
                title: 'Candidate saved.',
              })
            }
            className=" overflow-none text-ellipsis whitespace-nowrap rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-semibold text-gray-700"
          >
            Save candidate
          </button>
          <button
            className=" overflow-none text-ellipsis whitespace-nowrap rounded-lg border border-red-500 bg-red-500 px-4 py-2.5 text-sm font-semibold text-white"
            onClick={() => setIsOfferVacancyShow(true)}
          >
            Offer a vacancy
          </button>
        </div>
      </div>
    </>
  );
};
