import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { COUNTRIES } from '../../../../constants';
import { Input } from '../../../../ui/form/Input';
import { Select } from '../../../../ui/form/Select';
import StepIndicator from '../../StepIndicator';

import { OnboardingFormData } from '../../../../types';

import logo from '../../../../assets/redstone-icon.svg';
import { OnboardingSteps } from '../../../../enum';

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

type Props = {
  setCurrentFormProps: (data: FormType) => void;
  setCurrentFormData: (data: Partial<OnboardingFormData>) => void;
  formData: OnboardingFormData;
  setCurrentForm: (data: OnboardingSteps) => void;
};

export function FirstStep({
  setCurrentFormProps,
  setCurrentFormData,
  setCurrentForm,
  formData,
}: Props) {
  const [isFormClear, setIsFormClear] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      name: formData.name || '',
      last_name: formData.last_name || '',
      email_address: formData.email_address || '',
      country: formData.country || COUNTRIES[0].title,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(),
      last_name: yup.string().required(),
      email_address: yup.string().required(),
      country: yup.string().required(),
    }),
    onSubmit: (data) => {
      console.log(data, 'Submit data');
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formik.handleSubmit(event);

    if (isFormClear) {
      setCurrentForm(OnboardingSteps.SecondStep);
    }
  };

  console.log(formik.errors, 'formik.errors');

  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;

    const isFormFilled = Object.values(formik.values).every(
      (value) => value !== '',
    );

    if (isFormValid && isFormFilled) {
      const updatedValues = Object.fromEntries(
        Object.entries(formik.values).filter(([key, value]) => value !== ''),
      );

      const updatedFormData = { ...formData, ...updatedValues };
      setCurrentFormData(updatedFormData);
      setIsFormClear(true);
    }
  }, [formik.errors, formik.values]);

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  return (
    <div className="relative flex items-center justify-center">
      <div className="mt-8 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="flex h-16 w-16 items-center justify-center rounded-full font-semibold">
            <img src={logo} alt="Redstone" />
          </div>
        </div>
        <h1
          className="mt-8 text-4xl font-semibold text-gray-900"
          style={{ color: '#0A1019' }}
        >
          Personal Information
        </h1>

        <p
          className="mt-4 text-center text-base text-gray-700"
          style={{ color: '#555A61', fontSize: '16px' }}
        >
          Please follow these 4 easy steps to create your
          <br /> company profile
        </p>

        <StepIndicator currentStep={1} totalSteps={3} />
        <form className="mt-2 w-full" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Name
            </label>
            <Input
              type="text"
              placeholder="Enter name"
              id="name"
              name="name"
              error={findError('name')}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>

          <div className="mt-4 flex flex-col sm:mt-0">
            <label
              htmlFor="last_name"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Last Name
            </label>
            <Input
              type="text"
              placeholder="Enter last name"
              id="last_name"
              name="last_name"
              error={findError('last_name')}
              value={formik.values.last_name}
              onChange={formik.handleChange}
            />
          </div>

          <div className="mt-4 flex flex-col sm:mt-0">
            <label
              htmlFor="email_address"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Email Address
            </label>
            <Input
              type="text"
              placeholder="Enter email address"
              id="email_address"
              name="email_address"
              error={findError('email_address')}
              value={formik.values.email_address}
              onChange={formik.handleChange}
            />
          </div>

          <div className="mt-4 flex flex-col sm:mt-0">
            <label
              htmlFor="country"
              className="mb-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Country
            </label>
            <Select
              id="country"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
            >
              {COUNTRIES.map(({ title }, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </Select>
            {findError('country') && (
              <p className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                {findError('country')}
              </p>
            )}
          </div>

          <div className="mb-4 mt-4 flex">
            <button
              onClick={() =>
                setCurrentFormProps(FormType.SetupOrganizationProfile)
              }
              className="w-1/2  rounded-md border border-solid border-gray-300 bg-white pb-1 pt-1 font-semibold text-gray-900 focus:outline-none"
            >
              Back
            </button>
            <button
              className="focus:shadow-outline ml-4 w-1/2  rounded bg-red-600 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
