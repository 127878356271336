import { InputHTMLAttributes } from 'react';

import { CommonInputProps, BaseInput } from './BaseInput';

type Props = CommonInputProps & InputHTMLAttributes<HTMLInputElement>;

export function Input({ error, ...restProps }: Props) {
  return (
    <BaseInput error={error}>
      {(baseProps) => <input type="text" {...baseProps} {...restProps} />}
    </BaseInput>
  );
}
