import React, { useContext } from 'react';
import Stepper from './Stepper';
import BusinessType from '../onboarding/screens/BusinessType';
import Deployment from '../onboarding/screens/Deployment';
import BusinessDetails from '../onboarding/screens/BusinessDetails';
import Integrations from '../onboarding/screens/Integration';
import PaymentDetails from '../onboarding/screens/PaymentDetails';
import { OnboardingContext } from '../context/onboarding';

interface StepperOverlayProps {
  currentStep: number;
  onNext: () => void;
  onPrevious: () => void;
  onCancel: () => void;
}

const StepperOverlay: React.FC<StepperOverlayProps> = ({
  currentStep,
  onNext,
  onPrevious,
  onCancel,
}) => {
  const { currentPage } = useContext(OnboardingContext);

  const steps = [
    {
      label: 'General',
      isActive: currentStep === 0,
      isCompleted: currentStep > 0,
    },
    {
      label: 'Deployment',
      isActive: currentStep === 1,
      isCompleted: currentStep > 1,
    },
    {
      label: 'Payment Details',
      isActive: currentStep === 2,
      isCompleted: currentStep > 2,
    },
  ];

  const renderContent = () => {
    switch (currentPage()) {
      case 0:
        return <BusinessType onNext={onNext} />;
      case 1:
        return <Deployment onNext={onNext} onPrevious={onPrevious} />;
      case 4:
        return <Integrations onNext={onNext} onPrevious={onPrevious} />;
      case 2:
        return <PaymentDetails onNext={onNext} onPrevious={onPrevious} />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center pb-12 pt-12">
      <div className="w-3/4 max-w-screen-lg divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          {/* Content goes here */}
          {/* We use less vertical padding on card headers on desktop than on body sections */}
          <Stepper steps={steps} />
        </div>
        <div className="max-h-[calc(100vh-200px)] overflow-y-auto px-4 py-5 sm:p-6">
          {' '}
          {renderContent()}
        </div>
        <div className="px-4 py-4 sm:px-6">
          {currentStep === steps.length - 1 ? (
            <>
              <button
                type="button"
                className="focus:ring-offset-2sm:ml-3 inline-flex w-full justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={onNext}
              >
                Create Account
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={onPrevious}
              >
                Previous
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
              {currentStep === steps.length - 2 ? (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onNext}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full cursor-not-allowed justify-center rounded-md border border-transparent bg-red-300 px-4 py-2 text-base font-medium text-white opacity-50 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  disabled
                >
                  Submit
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepperOverlay;
