import { FormGroup } from '../../../../ui/form/FormGroup';
import { Input } from '../../../../ui/form/Input';
import { Dialog } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BriefcaseIcon } from '../../../../assets/BriefcaseIcon';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface CreateVacancyProps {
  isOpen: any;
  onClose: () => void;
}

export const SaveTemplate = ({ isOpen, onClose }: CreateVacancyProps) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: yup.string().when('saveAsTemplate', {
        is: true,
        then: yup.string().required('Template name is required'),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });

  const onSubmit = () => {
    formik.handleSubmit();
  };
  return (
    <>
      <Dialog
        isShown={isOpen}
        onCloseComplete={onClose}
        hasFooter={false}
        hasHeader={false}
        preventBodyScrolling
      >
        <div className="flex flex-col">
          <div className="mb-4 flex flex-row justify-between pt-6">
            <div className="rounded-[10px] border border-gray-200 bg-[#FEE9E9] p-3 shadow-sm">
              <BriefcaseIcon />
            </div>
            <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
          </div>

          <div className="mb-5 flex flex-col gap-1">
            <div className="text-lg font-semibold">Offer a vacancy</div>
            <p className="text-sm text-gray-500">
              Your new project has been created. Invite colleagues to
              collaborate on this project.
            </p>
          </div>
          <div>
            <FormGroup
              className="my-6"
              label="Template Name"
              htmlFor="templateName"
            >
              <Input
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
                id="templateName"
                error={formik.errors.name}
                placeholder="Enter Name"
              />
            </FormGroup>
          </div>
          <div className="mb-6 mt-4 flex w-full gap-3">
            <button
              className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="w-full rounded-lg border border-[#D0D5DD] bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
              onClick={onSubmit}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
