// 'use client';

import { RiAddFill, RiBookOpenLine, RiDatabase2Line } from '@remixicon/react';
import { Accordion, AccordionBody, AccordionHeader, Card } from '@tremor/react';
import { useState } from 'react';
import { KpiModal } from './KpiModal';

export type KpiType = {
  id: number;
  name: string;
  description: string;
  href: string;
};

const data = [
  {
    id: 1,
    name: 'Absence Rate',
    description:
      'Total number of absence days divided by the total number of available working days.',
    href: '#',
  },
  {
    id: 2,
    name: 'Absenteeism Rate',
    description:
      'Number of absence employees divided by the total number of employees, typically expresses as a percentage.',
    href: '#',
  },
  {
    id: 3,
    name: 'Average Duration of Absence',
    description:
      'Total number of absence days divided by the number of absence incidents.',
    href: '#',
  },
  {
    id: 4,
    name: 'Leave Utilization Rate',
    description:
      'The proportation of allocated leave that is actually used by employees.',
    href: '#',
  },
  {
    id: 5,
    name: 'Leave Balance',
    description:
      'Remaining leave days for each employee, broken down by leave type (e.g. annual leave, sick leave)',
    href: '#',
  },
  {
    id: 6,
    name: 'Frequency of Absence',
    description:
      'The number of absence occurences per employee within a specific period.',
    href: '#',
  },
];

const data2 = [
  {
    id: 1,
    name: 'Compliance Rate',
    description:
      'The percentage of leave requests and approvals that comply with company policies and regulations.',
    href: '#',
  },
  {
    id: 2,
    name: 'Policy Violation Incidents',
    description: 'The number of times leave policies were violated.',
    href: '#',
  },
];

function ContentPlaceholder() {
  return (
    <div className="relative h-full overflow-hidden rounded bg-gray-50">
      <svg
        className="stroke-gray-20 absolute inset-0 h-full w-full"
        fill="none"
      >
        <defs>
          <pattern
            id="pattern-1"
            x="0"
            y="0"
            width="10"
            height="10"
            patternUnits="userSpaceOnUse"
          >
            <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
          </pattern>
        </defs>
        <rect
          stroke="none"
          fill="url(#pattern-1)"
          width="100%"
          height="100%"
        ></rect>
      </svg>
    </div>
  );
}

export default function Example() {
  const [showKpiModal, setShowKpiModal] = useState<boolean>(false);
  const [currentKpi, setCurrentKpi] = useState<KpiType | null>(null);

  const handleKpiOpen = (kpi: KpiType) => {
    setCurrentKpi(kpi);
    setShowKpiModal(true);
  };

  return (
    <>
      <KpiModal
        isOpen={showKpiModal}
        onClose={() => setShowKpiModal(false)}
        kpi={currentKpi}
      />
      <div className="border-b border-tremor-border bg-tremor-background-muted p-4 sm:p-6 lg:p-8">
        <header>
          <h1 className="text-tremor-title font-semibold text-tremor-content-strong">
            Dashboard and Reports
          </h1>
          <p className="text-tremor-default text-tremor-content">
            Explore and manage your reports
          </p>
          <div className="mt-8 w-full md:flex md:max-w-3xl md:items-stretch md:space-x-4">
            <Card className="w-full md:w-7/12">
              <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                <RiBookOpenLine
                  className="size-5 dark:text-dark-tremor-content text-tremor-content-subtle"
                  aria-hidden={true}
                />
              </div>
              <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                <a href="#" className="focus:outline-none">
                  {/* Extend link to entire card */}
                  <span className="absolute inset-0" aria-hidden={true} />
                  Executive Summary Dashboard
                </a>
              </h3>
              <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                High-level overview of key metrics and trends for senior
                management.
              </p>
            </Card>
            <Card className="mt-4 w-full md:mt-0 md:w-8/12">
              <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                <RiDatabase2Line
                  className="size-5 dark:text-dark-tremor-content text-tremor-content-subtle"
                  aria-hidden={true}
                />
              </div>
              <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                <a href="#" className="focus:outline-none">
                  {/* Extend link to entire card */}
                  <span className="absolute inset-0" aria-hidden={true} />
                  Detailed Absence Reports
                </a>
              </h3>
              <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                Comprehensive reports breaking down absences by department,
                team, individual employees.
              </p>
            </Card>
            <Card className="mt-4 w-full md:mt-0 md:w-8/12">
              <div className="dark:border-dark-tremor-border inline-flex items-center justify-center rounded-tremor-small border border-tremor-border p-2">
                <RiDatabase2Line
                  className="size-5 dark:text-dark-tremor-content text-tremor-content-subtle"
                  aria-hidden={true}
                />
              </div>
              <h3 className="dark:text-dark-tremor-content-strong mt-4 text-tremor-default font-medium text-tremor-content-strong">
                <a href="#" className="focus:outline-none">
                  {/* Extend link to entire card */}
                  <span className="absolute inset-0" aria-hidden={true} />
                  Compliance and Policy Reports
                </a>
              </h3>
              <p className="dark:text-dark-tremor-cont text-tremor-default text-tremor-content">
                Reports focused on compliance with leave policies and
                regulations.
              </p>
            </Card>
          </div>
        </header>
      </div>
      <div>
        <div className="py-6">
          <main>
            <div className="flex items-center justify-between">
              <h2 className="dark:text-dark-tremor-content-strong text-tremor-title font-semibold text-tremor-content-strong">
                Your statistics
              </h2>
              <button
                type="button"
                className="dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:hover:bg-dark-tremor-brand-emphasis dark:shadow-dark-tremor-input inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 py-2.5 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
              >
                <RiAddFill
                  className="size-5 -ml-1 shrink-0"
                  aria-hidden={true}
                />
                Add report
              </button>
            </div>
            <Accordion className="mt-6" defaultOpen={true}>
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Recent (3)
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data.slice(0, 3).map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                      onClick={() => handleKpiOpen(item)}
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Absence and Leave Management
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Compliance and Policy Adherence
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data2.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Employee Behaviour and Trends
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data2.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Operational Efficiency
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data2.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Financial Metrics
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data2.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion className="mt-6">
              <AccordionHeader className="text-sm font-medium text-gray-700">
                Advanced Analytics
              </AccordionHeader>
              <AccordionBody>
                <div className="grid grid-cols-1 gap-4 pb-1 sm:grid-cols-2 lg:grid-cols-3">
                  {data2.map((item) => (
                    <Card
                      key={item.id}
                      className="relative rounded-tremor-small p-2"
                    >
                      <div className="h-20">
                        <ContentPlaceholder />
                      </div>
                      <h3 className="dark:text-dark-tremor-content-strong mt-2 text-tremor-default font-medium text-tremor-content-strong">
                        <a href={item.href} className="focus:outline-none">
                          {/* Extend link to entire card */}
                          <span
                            className="absolute inset-0"
                            aria-hidden={true}
                          />
                          {item.name}
                        </a>
                      </h3>
                      <p className="dark:text-dark-tremor-content text-tremor-default text-tremor-content">
                        {item.description}
                      </p>
                    </Card>
                  ))}
                </div>
              </AccordionBody>
            </Accordion>
          </main>
        </div>
      </div>
    </>
  );
}
