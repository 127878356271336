import { Fragment, ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { fill } from '@cloudinary/url-gen/actions/resize';
import { CloudinaryImage } from '@cloudinary/url-gen';
import Slideover from '../../../app/components/Slideover';
import { useAuth0 } from '@auth0/auth0-react';

const myImage = new CloudinaryImage('redstone-icon_xwvsrc', {
  cloudName: 'dnzgb3sfo',
}).resize(fill().width(300).height(237));

const navigation = [
  { name: 'Home', icon: HomeIcon, href: '/' },
  {
    name: 'Calendar',
    icon: CalendarIcon,
    href: '/calendar',
    count: 3,
  },
  {
    name: 'Time Away',
    icon: FolderIcon,
    href: '/timeaway',
    count: 4,
  },
  {
    name: 'People',
    icon: InboxIcon,
    href: '/people',
    count: 5,
  },
  {
    name: 'Reports',
    icon: InboxIcon,
    href: '/report',
    count: 6,
  },
  {
    name: 'ATS',
    icon: InboxIcon,
    href: '/ats',
    count: 7,
  },
];

type Props = {
  children: ReactNode;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export function ControlPanelLayout({ children }: Props) {
  const { user, logout } = useAuth0();
  const [isNavigationTrayActive, setNotificationTrayActive] = useState(false);

  const userNavigation = [
    { name: 'Your profile', href: '/profile' },
    { name: 'Settings', href: '/settings' },
    {
      name: 'Sign out',
      onClick: async () => await logout(),
    },
  ];

  return (
    <>
      <div className="min-h-full min-w-full">
        <Disclosure as="nav" className="bg-white shadow-sm">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={myImage.toURL()}
                        alt="logo"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={myImage.toURL()}
                        alt="logo"
                      />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'border-red-500 text-gray-900'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* Profile dropdown 
                    <button
                      type="button"
                      className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        setNotificationTrayActive(true);
                      }}
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => {
                          console.log({ message: 'set to active' });
                          setNotificationTrayActive(true);
                        }}
                      />
                    </button>
                      */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user?.picture as string}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <NavLink
                                  to={item.href || ''}
                                  onClick={item.onClick}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                  )}
                                >
                                  {item.name}
                                </NavLink>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={NavLink}
                      to={item.href}
                      className={({ isActive }: any) =>
                        classNames(
                          isActive
                            ? 'border-red-500 bg-red-50 text-red-700'
                            : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                          'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                        )
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user?.picture as string}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {user?.given_name}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user?.email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        setNotificationTrayActive(true);
                      }}
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="py-10">
          <main>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              {children}
              {/*<Chatbot />*/}
            </div>
          </main>
        </div>
      </div>

      <Slideover
        active={isNavigationTrayActive}
        mutator={setNotificationTrayActive}
      />
    </>
  );
}
