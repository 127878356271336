import { useState } from 'react';
import StepperOverlay from '../../app/components/StepperOverlay';
import { OnboardingContext } from '../../app/context/onboarding';

export const OnboardingRoute = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [submission, setSubmission] = useState({});

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <OnboardingContext.Provider
      value={{
        nextPage: () => setCurrentStep(currentStep + 1),
        previousPage: () => setCurrentStep(currentStep - 1),
        currentPage: () => currentStep,
        storeValue: (value: object) =>
          setSubmission({ ...submission, ...value }),
      }}
    >
      <div className="fixed inset-0 z-10 max-w-xl overflow-y-auto">
        <StepperOverlay
          currentStep={currentStep}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onCancel={() => {}}
        />
      </div>
    </OnboardingContext.Provider>
  );
};
