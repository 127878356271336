import axios from 'axios';
import { Dialog } from 'evergreen-ui';
import { useState } from 'react';
import { TrashIcon } from '../../../assets/TrashIcon';
import { XMarkIcon } from '@heroicons/react/20/solid';

type Props = {
  isOpen: boolean;
  onClose(): void;
  refetch(): void;
  user: any;
};

export const DeletePeopleDialog = ({
  isOpen,
  onClose,
  refetch,
  user,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteUser = async (id: number) => {
    setIsDeleting(true);
    await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/v1/people/${id}`,
    });
    setIsDeleting(false);
    onClose();
    refetch();
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] border border-gray-200 bg-white p-3 shadow-sm">
            <TrashIcon className="h-6 w-6" />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">
            Are you sure you want to delete {user?.full_name}?
          </div>
          <div className="text-sm font-normal text-[#475467]">
            This user will also be removed from all teams
          </div>
        </div>

        <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-[#475467] px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={() => handleDeleteUser(user?.id)}
            disabled={isDeleting}
          >
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  );
};
