import React, { useState } from 'react';
import Select from 'react-select';

type Props = {
  users: any[];
  value: any;
  onChange(value: any[]): void;
};

export const MultiSelectWithBadges = ({ users, value, onChange }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const handleChange = (selectedOptions: any) => {
    setSelectedUsers(selectedOptions || []);
    onChange(selectedOptions || []);
  };

  const options = users?.map((user) => ({
    value: user.id,
    label: user.full_name,
    image: user.safe_profile_picture_url,
  }));

  return (
    <div>
      <Select
        isMulti
        options={options}
        onChange={handleChange}
        value={value}
        formatOptionLabel={(option: any) => (
          <div className="flex items-center">
            <img
              src={option.image}
              alt={option.label}
              className="mr-2 h-6 w-6 rounded-full"
            />
            {option.label}
          </div>
        )}
        getOptionValue={(option: any) => option.value}
      />
      {/* <div className="mt-4 flex flex-wrap gap-2">
        {selectedUsers?.map((user) => (
          <div
            key={user.id}
            className="flex items-center rounded-full bg-blue-100 px-3 py-1"
          >
            <img
              src={user.safe_profile_picture_url}
              alt={user.full_name}
              className="mr-2 h-6 w-6 rounded-full"
            />
            <span>{user.full_name}</span>
          </div>
        ))}
      </div> */}
    </div>
  );
};
