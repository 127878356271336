import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  EVENT_TYPES,
  EVENT_START_TIME_TYPES,
  EVENT_END_TIME_TYPES,
} from '../../../../constants';
import { useCreateEventMutation } from '../../../../services/event';
import { Dialog } from '../../../../ui/Dialog';
import { FormGroup } from '../../../../ui/form/FormGroup';
import { Select } from '../../../../ui/form/Select';
import { Input } from '../../../../ui/form/Input';
import { Textarea } from '../../../../ui/form/Textarea';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export function EventDialog({ isOpen, onClose }: Props) {
  const [createEvent] = useCreateEventMutation();

  const formik = useFormik({
    initialValues: {
      type: EVENT_TYPES[0].title,
      start: '',
      startTimeOfDay: EVENT_START_TIME_TYPES[0],
      end: '',
      endTimeOfDay: EVENT_END_TIME_TYPES[0],
      description: '',
    },
    validationSchema: yup.object({
      type: yup.string().required(),
      start: yup.date().required(),
      startTimeOfDay: yup.string().required(),
      end: yup
        .date()
        .required()
        .min(yup.ref('start'), "End date can't be before start date"),
      endTimeOfDay: yup.string().required(),
      description: yup.string(),
    }),
    onSubmit: ({ start, end, ...restValues }) => {
      createEvent({
        start: {
          date: moment(start).format('YYYY-MM-DD'),
          time: 'start_of_day',
        },
        end: {
          date: moment(end).format('YYYY-MM-DD'),
          time: 'end_of_day',
        },
        ...restValues,
      });
      closeDialog();
    },
  });

  function closeDialog() {
    formik.resetForm();
    onClose();
  }

  function findError(fieldName: keyof typeof formik.initialValues) {
    return formik.touched[fieldName] && formik.errors[fieldName]
      ? formik.errors[fieldName]
      : undefined;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeDialog}
      onApply={formik.handleSubmit}
      title="Add event"
    >
      <form className="mb-4" onSubmit={formik.handleSubmit}>
        <div className="space-y-6 sm:space-y-5">
          <FormGroup label="Type" htmlFor="event-type">
            <Select
              id="event-type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              {EVENT_TYPES.map(({ title }, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup label="Start date" htmlFor="event-start-date">
            <Input
              id="event-start-date"
              type="date"
              name="start"
              error={findError('start')}
              value={formik.values.start}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <FormGroup label="Start time" htmlFor="event-start-time">
            <Select
              id="event-start-time"
              name="startTimeOfDay"
              value={formik.values.startTimeOfDay}
              onChange={formik.handleChange}
            >
              {EVENT_START_TIME_TYPES.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup label="End date" htmlFor="event-end-date">
            <Input
              id="event-end-date"
              type="date"
              name="end"
              error={findError('end')}
              value={formik.values.end}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <FormGroup label="End time" htmlFor="event-end-time">
            <Select
              id="event-end-time"
              name="endTimeOfDay"
              value={formik.values.endTimeOfDay}
              onChange={formik.handleChange}
            >
              {EVENT_END_TIME_TYPES.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup label="Description" htmlFor="event-description">
            <Textarea
              id="event-description"
              name="description"
              error={findError('description')}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </FormGroup>
        </div>
      </form>
    </Dialog>
  );
}
