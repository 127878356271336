import React from 'react';

export const BriefcaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.3307 6.33333C13.3307 5.55836 13.3307 5.17087 13.2455 4.85295C13.0144 3.99022 12.3405 3.31635 11.4778 3.08519C11.1599 3 10.7724 3 9.9974 3C9.22242 3 8.83493 3 8.51702 3.08519C7.65429 3.31635 6.98042 3.99022 6.74925 4.85295C6.66406 5.17087 6.66406 5.55836 6.66406 6.33333M4.33073 18H15.6641C16.5975 18 17.0642 18 17.4207 17.8183C17.7343 17.6586 17.9893 17.4036 18.1491 17.09C18.3307 16.7335 18.3307 16.2668 18.3307 15.3333V9C18.3307 8.06658 18.3307 7.59987 18.1491 7.24335C17.9893 6.92975 17.7343 6.67478 17.4207 6.51499C17.0642 6.33333 16.5975 6.33333 15.6641 6.33333H4.33073C3.39731 6.33333 2.9306 6.33333 2.57408 6.51499C2.26047 6.67478 2.00551 6.92975 1.84572 7.24335C1.66406 7.59987 1.66406 8.06658 1.66406 9V15.3333C1.66406 16.2668 1.66406 16.7335 1.84572 17.09C2.00551 17.4036 2.26047 17.6586 2.57408 17.8183C2.9306 18 3.39731 18 4.33073 18Z"
        stroke="#DC2626"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
