import { useState } from 'react';

export const DescriptionExpandable = ({
  description,
}: {
  description: string;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className="cursor-pointer text-base font-normal text-gray-900">
      {isExpanded ? (
        <div
          className="text-base font-normal text-gray-500"
          onClick={() => setIsExpanded(false)}
        >
          {description}
        </div>
      ) : (
        <div
          className="text-base font-normal text-gray-500"
          onClick={() => setIsExpanded(true)}
        >
          {description.slice(0, 200)}...
        </div>
      )}
    </div>
  );
};
