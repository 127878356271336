import React, { useState } from 'react';

interface IntegrationsProps {
  onNext: () => void;
  onPrevious: () => void;
}

const Integrations: React.FC<IntegrationsProps> = ({ onNext, onPrevious }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importedUsers, setImportedUsers] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleConnect = async () => {
    try {
      // TODO: Implement OAuth flow here.

      setIsImporting(true);

      // Simulating user import for the example.
      setTimeout(() => {
        setImportedUsers(['user1@example.com', 'user2@example.com']); // Sample data
        setIsImporting(false);
        setIsConnected(true);
      }, 2000);
    } catch (err) {
      setError('Failed to connect to Google Workspace.');
      setIsImporting(false);
    }
  };

  const handleDisconnect = () => {
    // TODO: Handle disconnect logic, revoke token, etc.
    setIsConnected(false);
    setImportedUsers([]);
  };

  return (
    <div className="p-8">
      <h2 className="mb-4 text-lg font-bold">Google Workspace Integration</h2>
      <p className="mb-4 text-gray-700">
        Connect your Google Workspace account to import all your users
        seamlessly.
      </p>

      {error && <div className="mb-4 text-red-500">{error}</div>}

      {!isConnected ? (
        <button
          className="bg-blue-500 hover:bg-blue-700 mb-4 rounded px-4 py-2 font-bold text-white"
          onClick={handleConnect}
        >
          Connect to Google Workspace
        </button>
      ) : (
        <>
          {isImporting ? (
            <div className="mb-4 text-gray-700">Importing users...</div>
          ) : (
            <>
              <div className="mb-4 text-green-600">Connected successfully!</div>
              <div className="mb-4 text-gray-700">
                Imported Users: {importedUsers.join(', ')}
              </div>
              <button
                className="mb-4 rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                onClick={handleDisconnect}
              >
                Disconnect
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Integrations;
