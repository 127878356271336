import colors from 'tailwindcss/colors';

export const CALENDAR_MODE__MONTH = 'Month';
export const CALENDAR_MODE__WEEK = 'Week';
export const CALENDAR_MODE__DAY = 'Day';

export const EVENT_TYPES: {
  title: string;
  colorName: keyof typeof colors;
}[] = [
  { title: 'Holiday (Paid)', colorName: 'red' },
  { title: 'Holiday (Unpaid)', colorName: 'yellow' },
  { title: 'Working remotely', colorName: 'emerald' },
  { title: 'Sick leave', colorName: 'sky' },
  { title: 'Parental leave', colorName: 'violet' },
  { title: 'Other leave', colorName: 'pink' },
  { title: 'Public holiday', colorName: 'violet' },
  { title: 'Non-contracted day', colorName: 'emerald' },
];

export const EVENT_TIME_TYPE__START = 'the start of the day';
export const EVENT_TIME_TYPE__MIDDAY = 'midday';
export const EVENT_TIME_TYPE__END = 'the end of the day';

export const EVENT_START_TIME_TYPES = [
  EVENT_TIME_TYPE__START,
  EVENT_TIME_TYPE__MIDDAY,
];

export const STAGES = [
  {
    value: 'interview',
    title: 'Interview',
  },
  {
    value: 'technicalTest',
    title: 'Technical Test',
  },
  {
    value: 'hrScreening',
    title: 'HR Screening',
  },
  {
    value: 'teamInterview',
    title: 'Team Interview',
  },
  {
    value: 'finalInterview',
    title: 'Final Interview',
  },
  {
    value: 'offer',
    title: 'Offer',
  },
  {
    value: 'hired',
    title: 'Hired',
  },
  {
    value: 'rejected',
    title: 'Rejected',
  },
];

export const SKILLS = [
  { value: 'programming', title: 'Programming' },
  { value: 'web-development', title: 'Web Development' },
  { value: 'database-management', title: 'Database Management' },
  { value: 'cybersecurity', title: 'Cybersecurity' },
  { value: 'data-analysis', title: 'Data Analysis' },
  { value: 'cloud-computing', title: 'Cloud Computing' },
  { value: 'networking', title: 'Networking' },
  { value: 'machine-learning', title: 'Machine Learning' },
  { value: 'devops', title: 'DevOps' },
  { value: 'ux-ui-design', title: 'UX/UI Design' },
];

export const WORKPLACE_TYPES = [
  { value: 'on-site', title: 'On-site' },
  { value: 'remote', title: 'Remote' },
  { value: 'hybrid', title: 'Hybrid' },
  { value: 'co-working', title: 'Co-working' },
  { value: 'field-work', title: 'Field Work' },
  { value: 'home-office', title: 'Home Office' },
  { value: 'traveling', title: 'Traveling' },
];

export const JOB_VACANCY_TYPES = [
  { value: 'reserch-and-development', title: 'Reserch & Development' },
  { value: 'ui-ux', title: 'UI/UX' },
];

export const COMPANY_SYZES = [
  { value: '10001', title: '10,1001+ employeers' },
  { value: '100', title: '100+ employees' },
];

export const WORK_LEVEL = [
  { value: 'trainee', title: 'Trainee' },
  { value: 'junior', title: 'Junior' },
  { value: 'middle', title: 'Middle' },
  { value: 'senior', title: 'Senior' },
];

export const SALARY_TIME = [
  { value: 'per-week', title: 'Per week' },
  { value: 'per-month', title: 'Per month' },
  { value: 'per-year', title: 'Per Year' },
];

export const CURRENCY_TYPE = [
  { value: 'usd', title: 'USD' },
  { value: 'eur', title: 'EUR' },
  { value: 'gbp', title: 'GBP' },
];

export const TIME = [
  { value: '10:00 AM' },
  { value: '10:30 AM' },
  { value: '11:00 AM' },
  { value: '11:30 AM' },
  { value: '12:00 AM' },
  { value: '12:30 AM' },
  { value: '01:00 PM' },
  { value: '01:30 PM' },
  { value: '02:00 pM' },
];

export const PEOPLE_SEX = [
  { title: 'Male', value: 'SEX_MALE' },
  { title: 'Female', value: 'SEX_FEMALE' },
];

export const COUNTRIES = [
  { title: 'USA', value: 'USA' },
  { title: 'Ukraine', value: 'UA' },
];

export const EVENT_END_TIME_TYPES = [
  EVENT_TIME_TYPE__END,
  EVENT_TIME_TYPE__MIDDAY,
];

export const ROLE_TYPES = [
  { title: 'Software Engineer', value: 'SOFTWARE_ENGINEER' },
  { title: 'Joint Managing Director', value: 'JOINT_MANAGING_DIRECTORY' },
  { title: 'Assistant Practice', value: 'ASSISTANT_PRACTICE' },
  { title: 'IT Technician', value: 'IT_TECHNICIAN' },
  { title: 'IT Manager', value: 'IT_MANAGER' },
  { title: 'Office Administrator', value: 'OFFICE_ADMINISTRATOR' },
];

export const DEFAULT_TEAM = [
  { title: 'Development Team', value: 'DEVELOPMENT_TEAM' },
  { title: 'Administrator Team', value: 'ADMINISTRATOR_TEAM' },
];

export const TIMEZONES = [
  { title: 'Eastern Time (USA & Canada) GMT-05:00', value: 'ET' },
  { title: 'Central Time (USA & Canada) GMT-06:00', value: 'CT' },
  { title: 'Mountain Time (USA & Canada) GMT-07:00', value: 'MT' },
  { title: 'Pacific Time (USA & Canada) GMT-08:00', value: 'PT' },
];

export const WORKING_OPTIONS = [
  { title: 'Full-time', value: 'Full-time' },
  { title: 'Part-time', value: 'Part-time' },
  { title: 'Self-employed', value: 'Self-employed' },
  { title: 'Contract', value: 'Contract' },
  { title: 'Seasonal', value: 'Seasonal' },
];

export const ALLOWANCE = [
  { title: 'Unpaid Leave', value: 'unpaid_leave' },
  { title: 'Paid Holiday', value: 'paid_holiday' },
  { title: 'Paid Sick Leave Per Year', value: 'paid_seek_leave_per_year' },
  { title: 'Remote Working Per Week', value: 'remote_working_per_week' },
];
