import { configureStore } from '@reduxjs/toolkit';

import { userApi } from '../services/user';
import { eventApi } from '../services/event';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});
