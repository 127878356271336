import { createContext } from 'react';

export enum NotificationTabs {
  AuthNotification = 'AUTH_NOTIFICATION',
}

interface NotificationContextProps {
  isActive: (tab: NotificationTabs) => boolean;
  setActive: (tab: NotificationTabs) => void;
}

export const NotificationContext = createContext<NotificationContextProps>(
  {
    isActive: () => false,
    setActive: () => {},
  } || null,
);
