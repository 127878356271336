import { useEffect, useState } from 'react';

import { SetupOrganizationProfile } from '../../../app/components/SetupOrganizationProfile';
import { PersonalInfo } from '../../../app/components/PersonalInfo';

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

export function OnboardingLayout() {
  const [currentForm, setCurrentForm] = useState(
    FormType.SetupOrganizationProfile,
  );

  const [formData, setFormData] = useState({
    website: '',
    company_name: '',
    company_size: '',
    app_sumo_code: '',
    name: '',
    last_name: '',
    email_address: '',
    country: '',
    role: '',
    timezone: '',
    subdomain: '',
    invoce_email_address: '',
    logo: '',
    working_options: '',
    unpaid_leave: '',
    paid_holiday: '',
    paid_seek_leave_per_year: '',
    remote_working_per_week: '',
  });

  const setCurrentFormData = (data: any) => {
    setFormData(data);
  };

  useEffect(() => {
    console.log(formData, 'formData');
  }, [formData]);

  const renderForm = () => {
    switch (currentForm) {
      case FormType.SetupOrganizationProfile:
        return (
          <SetupOrganizationProfile
            setCurrentForm={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      case FormType.PersonalInformation:
        return (
          <PersonalInfo
            setCurrentFormProps={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      default:
        return null;
    }
  };

  return <div className="min-h-full min-w-full bg-white">{renderForm()}</div>;
}
