import { RiArrowLeftLine, RiSearchLine } from '@remixicon/react';
import {
  Button,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TextInput,
} from '@tremor/react';
import React, { useMemo, useState } from 'react';
import { Pagination } from '../../../../ui/component/pagination';
import { CandidatesTable } from './CandidatesTable';
import { CandidateType } from '../../../../mirage/types';
import Fuse from 'fuse.js';
import { Select, SelectItem } from '@tremor/react';
import { SavedCandidatesTable } from './SavedCandidates';
import ResumeDetailCard from './ResumeDetailCard';

const candidatesWhoResponded: CandidateType[] = [
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date('2024-08-11T17:20:00'),
    resume: {
      title: 'CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 2,
    position: 'Backend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date('2024-08-11T17:20:00'),
    resume: {
      title: 'CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 3,
    position: 'Full Stack Developer',
    name: 'Charlie Davis',
    location: 'Austin, TX',
    description:
      'Proficient in both frontend and backend technologies with a knack for building complete web applications.',
    skills: ['JavaScript', 'React', 'Node.js', 'PostgreSQL'],
    date: new Date('2024-08-02T10:15:00'),
    resume: {
      title: 'Resume.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 4,
    position: 'Senior DevOps Engineer',
    name: 'Danielle White',
    location: 'Seattle, WA',
    description:
      'Experienced in maintaining and automating CI/CD pipelines and cloud infrastructure.',
    skills: ['AWS', 'Docker', 'Kubernetes', 'Jenkins'],
    date: new Date('2024-08-03T09:30:00'),
    resume: {
      title: 'DanielleWhite_CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 5,
    position: 'Lead Data Scientist',
    name: 'Evan Green',
    location: 'Boston, MA',
    description:
      'Data scientist with expertise in machine learning and statistical analysis.',
    skills: ['Python', 'R', 'TensorFlow', 'SQL'],
    date: new Date('2024-08-10T14:45:00'),
    resume: {
      title: 'EvanGreen_Resume.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 6,
    position: 'Senior UI/UX Designer',
    name: 'Fiona Martin',
    location: 'Los Angeles, CA',
    description:
      'Creative designer with a strong portfolio in user interface and user experience design.',
    skills: ['Sketch', 'Figma', 'Adobe XD', 'User Research'],
    date: new Date('2024-08-05T16:00:00'),
    resume: {
      title: 'FionaMartin_Portfolio.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 7,
    position: 'Mobile Developer',
    name: 'George Clark',
    location: 'Denver, CO',
    description:
      'Mobile developer with experience in building iOS and Android applications.',
    skills: ['Swift', 'Kotlin', 'React Native', 'Flutter'],
    date: new Date('2024-08-06T11:20:00'),
    resume: {
      title: 'GeorgeClark_Resume.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 8,
    position: 'Senior Product Manager',
    name: 'Hannah Lewis',
    location: 'Chicago, IL',
    description:
      'Product manager with a proven track record of successful product launches.',
    skills: ['Product Strategy', 'Agile', 'Scrum', 'Roadmap Planning'],
    date: new Date('2024-08-07T13:50:00'),
    resume: {
      title: 'HannahLewis_CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 9,
    position: 'Data Analyst',
    name: 'Ian Wright',
    location: 'Dallas, TX',
    description:
      'Data analyst with expertise in data visualization and business intelligence tools.',
    skills: ['Excel', 'Power BI', 'Tableau', 'SQL'],
    date: new Date('2024-08-08T15:10:00'),
    resume: {
      title: 'IanWright_CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 10,
    position: 'Cybersecurity Specialist',
    name: 'Jennifer Brown',
    location: 'Washington, D.C.',
    description:
      'Cybersecurity expert with experience in threat analysis and vulnerability management.',
    skills: ['Ethical Hacking', 'Network Security', 'Python', 'Firewalls'],
    date: new Date('2024-08-09T12:30:00'),
    resume: {
      title: 'JenniferBrown_Resume.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 11,
    position: 'AI Researcher',
    name: 'Kevin Johnson',
    location: 'San Jose, CA',
    description:
      'AI researcher with extensive experience in developing machine learning models.',
    skills: ['Python', 'TensorFlow', 'PyTorch', 'NLP'],
    date: new Date('2024-08-10T10:00:00'),
    resume: {
      title: 'KevinJohnson_CV.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
  {
    id: 12,
    position: 'QA Engineer',
    name: 'Liam Martinez',
    location: 'Portland, OR',
    description:
      'QA engineer with a strong background in automated testing and quality assurance.',
    skills: ['Selenium', 'JUnit', 'TestNG', 'Java'],
    date: new Date('2024-08-11T09:45:00'),
    resume: {
      title: 'LiamMartinez_Resume.pdf',
      url: 'https://placehold.co/600x400',
    },
    department: 'Research & Development',
    status: 'Saved',
  },
];

export const ResumeManagment = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCurrentUser, setShowCurrentUser] = useState(false);
  const [candidateData, setCandidateData] = useState({});

  const fuse = useMemo(() => {
    return new Fuse(candidatesWhoResponded, {
      keys: [
        'jobTitle',
        'fullname',
        'skills',
        'position',
        'platform',
        'location',
        'description',
      ],
      threshold: 0.3,
    });
  }, [candidatesWhoResponded]);

  const filteredCandidates: CandidateType[] = useMemo(() => {
    if (!searchTerm) {
      return candidatesWhoResponded as CandidateType[];
    }
    return fuse.search(searchTerm).map((result) => result.item);
  }, [searchTerm, fuse]);

  const showUser = (candidate: CandidateType) => {
    setShowCurrentUser(true);
    setCandidateData(candidate);
  };

  return (
    <>
      {showCurrentUser ? (
        <div>
          <Button
            icon={RiArrowLeftLine}
            onClick={() => setShowCurrentUser(false)}
            className="mb-4 border-gray-300 bg-white text-gray-900"
            iconPosition="left"
            variant="secondary"
          >
            Back to Page
          </Button>
          <ResumeDetailCard candidateData={candidateData} />
        </div>
      ) : (
        <div className="rounded-xl bg-white shadow">
          <div className="flex flex-row items-center justify-between px-6 py-5">
            <div>
              <div className="text-lg font-semibold text-gray-900">
                Resume Managment
              </div>
              <div className="text-sm font-normal text-gray-600">
                All candidates whose resumes you have saved by creating a
                profile
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <TextInput
                icon={RiSearchLine}
                placeholder="Search..."
                className="w-auto lg:w-[400px]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[1px] !min-w-full bg-gray-300 p-0"></div>
          <div>
            <TabGroup>
              <div className="flex items-center justify-between px-6 pt-2">
                <TabList variant="solid" color="orange">
                  <Tab>Candidates who responded</Tab>
                  <Tab>Saved</Tab>
                </TabList>
                <div className="flex items-center gap-2">
                  <Select className="w-[22%]" defaultValue="1">
                    <SelectItem value="1">All Position</SelectItem>
                    <SelectItem value="2">Option Two</SelectItem>
                    <SelectItem value="3">Option Three</SelectItem>
                  </Select>
                  <Select className="w-[22%]" defaultValue="1">
                    <SelectItem value="1">All Time</SelectItem>
                    <SelectItem value="2">Option Two</SelectItem>
                    <SelectItem value="3">Option Three</SelectItem>
                  </Select>
                  <Select className="w-[22%]" defaultValue="1">
                    <SelectItem value="1">All Location</SelectItem>
                    <SelectItem value="2">Option Two</SelectItem>
                    <SelectItem value="3">Option Three</SelectItem>
                  </Select>
                </div>
              </div>
              <TabPanels>
                <TabPanel>
                  <CandidatesTable
                    showUser={showUser}
                    candidates={filteredCandidates}
                  />
                </TabPanel>
                <TabPanel>
                  <SavedCandidatesTable
                    showUser={showUser}
                    candidates={filteredCandidates}
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <Pagination
              limit={20}
              page={1}
              count={100}
              onPageChange={() => console.log()}
            />
          </div>
        </div>
      )}
    </>
  );
};
