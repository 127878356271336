import React, { useState } from 'react';
import { Button } from '@tremor/react';

interface BurgerMenuButtonProps {
  noLine?: boolean;
}

const BurgerMenuButton: React.FC<BurgerMenuButtonProps> = ({ noLine }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Button
      onClick={toggleMenu}
      className={`relative flex h-10 w-10 items-center justify-center rounded-md bg-white !shadow-none hover:border-gray-200 hover:bg-gray-100 ${
        noLine ? 'border-none' : 'border border-gray-300 '
      }`}
    >
      <div className="flex h-5 w-5 transform flex-col justify-between">
        <span
          className={`block h-0.5 w-full transform bg-black transition-transform duration-300 ease-in-out ${
            isOpen && 'translate-y-2 rotate-45'
          }`}
        />
        <span
          className={`block h-0.5 w-full bg-black transition-opacity duration-300 ease-in-out ${
            isOpen && 'opacity-0'
          }`}
        />
        <span
          className={`block h-0.5 w-full transform bg-black transition-transform duration-300 ease-in-out ${
            isOpen && '-translate-y-2 -rotate-45'
          }`}
        />
      </div>
    </Button>
  );
};

export default BurgerMenuButton;
