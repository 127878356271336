import { createContext } from 'react';

export interface OnboardingContextProps {
  nextPage: () => void;
  previousPage: () => void;
  currentPage: () => number;
  storeValue: (value: object) => void;
}

export const OnboardingContext = createContext<OnboardingContextProps>({
  nextPage: () => {},
  previousPage: () => {},
  currentPage: () => 0,
  storeValue: () => {},
});
