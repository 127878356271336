import { Fragment, ReactNode } from 'react';
import classnames from 'classnames';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useFocusRemover } from '../hooks/useFocusRemover';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onApply?: () => void;
  title: ReactNode;
  panelClassName?: string;
  noActions?: boolean;
  children?: ReactNode;
};

export function Dialog({
  isOpen,
  onClose,
  onApply,
  title,
  panelClassName,
  noActions = false,
  children,
}: Props) {
  const { setFocusedElement } = useFocusRemover();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          className="fixed inset-0 overflow-y-auto"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="flex min-h-full justify-center text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessDialog.Panel
                className={classnames(
                  'w-full transform overflow-hidden rounded-none bg-white text-left align-middle shadow-xl transition-all sm:max-w-md sm:rounded-xl',
                  panelClassName,
                )}
              >
                <HeadlessDialog.Title
                  as="h3"
                  className="flex flex-auto items-center justify-between p-4 text-lg font-medium leading-6 text-gray-900 sm:p-5"
                >
                  {title}
                  <button
                    ref={setFocusedElement}
                    type="button"
                    className="ml-5 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </HeadlessDialog.Title>
                <div className="p-4 sm:p-5">{children}</div>
                {!noActions && (
                  <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto"
                      onClick={onApply}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-5 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}
