import { ReactNode } from 'react';

type Props = {
  label: string;
  htmlFor?: string;
  children: ReactNode;
  className?: string;
};

export function FormGroup({ label, htmlFor = '', children, className }: Props) {
  return (
    <div className={`flex flex-col ${className}`}>
      <label
        htmlFor={htmlFor}
        className="block pb-1.5 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">{children}</div>
    </div>
  );
}
