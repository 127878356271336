import React, { useState } from 'react';

interface PaymentDetailsProps {
  onNext: () => void;
  onPrevious: () => void;
}

interface BankDetails {
  bankName?: string; // Added for USA
  accountNumber: string;
  routingNumber?: string; // Added for USA
  sortCode?: string; // For UK
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  onNext,
  onPrevious,
}) => {
  const [bankDetails, setBankDetails] = useState<BankDetails>({
    accountNumber: '',
    sortCode: '',
  });
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const handleInputChange =
    (field: keyof BankDetails) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBankDetails({ ...bankDetails, [field]: event.target.value });
    };

  return (
    <div className="p-8">
      <div className="mb-4">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700"
        >
          Select Country
        </label>
        <select
          id="country"
          name="country"
          className="mt-1 w-full rounded-md border-gray-300 p-2"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          <option value="">Select Country</option>
          <option value="USA">United States</option>
          <option value="UK">United Kingdom</option>
        </select>
      </div>

      {selectedCountry === 'USA' && (
        <>
          <div className="mb-4">
            <label
              htmlFor="bankName"
              className="block text-sm font-medium text-gray-700"
            >
              Bank Name
            </label>
            <input
              type="text"
              name="bankName"
              id="bankName"
              className="mt-1 w-full rounded-md border-gray-300 p-2"
              value={bankDetails.bankName}
              onChange={handleInputChange('bankName')}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="accountNumberUSA"
              className="block text-sm font-medium text-gray-700"
            >
              Account Number
            </label>
            <input
              type="text"
              name="accountNumberUSA"
              id="accountNumberUSA"
              className="mt-1 w-full rounded-md border-gray-300 p-2"
              value={bankDetails.accountNumber}
              onChange={handleInputChange('accountNumber')}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="routingNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Routing Number
            </label>
            <input
              type="text"
              name="routingNumber"
              id="routingNumber"
              className="mt-1 w-full rounded-md border-gray-300 p-2"
              value={bankDetails.routingNumber}
              onChange={handleInputChange('routingNumber')}
            />
          </div>
        </>
      )}

      {selectedCountry === 'UK' && (
        <>
          <div className="mb-4">
            <label
              htmlFor="accountNumberUK"
              className="block text-sm font-medium text-gray-700"
            >
              Account Number
            </label>
            <input
              type="text"
              name="accountNumberUK"
              id="accountNumberUK"
              className="mt-1 w-full rounded-md border-gray-300 p-2"
              value={bankDetails.accountNumber}
              onChange={handleInputChange('accountNumber')}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="sortCode"
              className="block text-sm font-medium text-gray-700"
            >
              Sort Code
            </label>
            <input
              type="text"
              name="sortCode"
              id="sortCode"
              className="mt-1 w-full rounded-md border-gray-300 p-2"
              value={bankDetails.sortCode}
              onChange={handleInputChange('sortCode')}
            />
          </div>
        </>
      )}

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PaymentDetails;
