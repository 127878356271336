import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TextInput,
} from '@tremor/react';
import { CandidateType } from '../../../../mirage/types';
import { CandidateList } from '../Candidates/CandidateList';
import { Pill } from 'evergreen-ui';
import { RiSearchLine } from '@remixicon/react';
import { useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { RespondedCandidateList } from '../Candidates/RespondedCandidates/RespondedCandidateList';
import ResumeCard from './ResumeComp';
import { RiArrowLeftLine } from '@remixicon/react';
import { Button } from '@tremor/react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { da } from 'date-fns/locale';

const { REACT_APP_API_HOST } = process.env;

const candidates: CandidateType[] = [
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
];

const candidatesWhoResponded: CandidateType[] = [
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
  {
    id: 1,
    position: 'Frontend Developer',
    name: 'Alice Johnson',
    location: 'San Francisco, CA',
    resume: {
      title: 'My CV',
      url: 'https://placehold.co/600x400',
    },
    status: 'Applied',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum dolorem excepturi perspiciatis reprehenderit saepe nulla corporis hic, voluptas distinctio nesciunt facilis temporibus quibusdam dolore eveniet minus eum tempora ut? Adipisci.',
    skills: ['JavaScript', 'React', 'CSS', 'HTML'],
    date: new Date(),
    department: 'Research & Development',
  },
];

export const FindCandidates = () => {
  const [token, setToken] = useState<string>();

  const [searchTerm, setSearchTerm] = useState('');
  const [showCurrentUser, setShowCurrentUser] = useState(false);
  const [candidateData, setCandidateData] = useState({});

  const { data: response = [] } = useQuery({
    queryKey: ['candidates', 'pending'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/ats/candidate`,
        params: { status: 'Pending' },
      });

      return data;
    },
  });

  const { data: applied_response = [] } = useQuery({
    queryKey: ['candidates', 'applied'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/ats/candidate`,
        headers: { Authorization: `Bearer ${token}` },
        params: { status: 'Applied' },
      });

      return data;
    },
  });

  const showUser = (candidate: CandidateType) => {
    setShowCurrentUser(true);
    setCandidateData(candidate);
  };

  const fuse = useMemo(() => {
    return new Fuse(candidates, {
      keys: [
        'jobTitle',
        'fullname',
        'skills',
        'position',
        'platform',
        'location',
        'description',
        'department',
      ],
      threshold: 0.3,
    });
  }, [candidates]);

  const filteredCandidates: CandidateType[] = useMemo(() => {
    if (!searchTerm) {
      return candidates as CandidateType[];
    }
    return fuse.search(searchTerm).map((result) => result.item);
  }, [searchTerm, fuse]);

  return (
    <>
      {showCurrentUser ? (
        <div>
          <Button
            icon={RiArrowLeftLine}
            onClick={() => setShowCurrentUser(false)}
            className="mb-4 border-gray-300 bg-white text-gray-900"
            iconPosition="left"
            variant="secondary"
          >
            Back to Page
          </Button>
          <ResumeCard candidateData={candidateData} />
        </div>
      ) : (
        <div className="rounded-xl bg-white shadow">
          <div className="flex flex-row items-center justify-between px-6 py-5">
            <div>
              <div className="text-lg font-semibold text-gray-900">
                Candidate
              </div>
              <div className="text-sm font-normal text-gray-600">
                Candidates who responded to your vacancies
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <TextInput
                icon={RiSearchLine}
                placeholder="Search..."
                className="w-auto lg:w-[400px]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[1px] !min-w-full bg-gray-300 p-0"></div>
          <div>
            <TabGroup>
              <TabList variant="line" color="red-500" className="m-6">
                <Tab>All Candidates</Tab>
                <Tab>
                  <div className="flex flex-row items-center gap-1">
                    <div>Candidates who responded</div>{' '}
                    <Pill className="text-xs font-medium" color="green">
                      {candidatesWhoResponded.length}
                    </Pill>
                  </div>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <CandidateList
                    showUser={showUser}
                    candidates={response?.data || []}
                  />
                </TabPanel>
                <TabPanel>
                  <RespondedCandidateList
                    showUser={showUser}
                    candidates={applied_response?.data || []}
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      )}
    </>
  );
};
