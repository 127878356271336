import { CandidateType } from '../../../../../mirage/types';
import { DescriptionExpandable } from '../DescriptionExpandable';
import { useState } from 'react';
import { ScheduleInterviewDialog } from '../../Dialogs/ScheduleInterviewDialog';
import moment from 'moment';
import { Pill } from 'evergreen-ui';
import BurgerMenuButton from '../../../../../ui/component/BurgerMenuIcon';
import { Badge } from '@tremor/react';

export const RespondedCandidateItem = ({
  candidate,
  showUser,
}: {
  candidate: CandidateType;
  showUser: (candidate: CandidateType) => void;
}) => {
  const [showScheduleInterview, setShowScheduleInterview] =
    useState<boolean>(false);
  let currentDate = moment();

  const candidateMeeting = () => {
    if (true) {
      let date = new Date();
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };
  return (
    <>
      <ScheduleInterviewDialog
        isOpen={showScheduleInterview}
        onClose={() => setShowScheduleInterview(false)}
        user={candidate}
      />
      <div className="flex flex-row justify-between gap-8 rounded-lg bg-white p-6 shadow ring-1 ring-black/5">
        <div className="flex w-full flex-col gap-3">
          <div className="flex flex-col gap-3">
            {currentDate.diff(moment(candidate.date), 'days') < 3 && (
              <Pill className="w-fit" color="green">
                <p className="text-xs font-medium">New</p>
              </Pill>
            )}
            <div className="flex flex-row items-center gap-3">
              <button
                rel="noreferrer"
                onClick={() => showUser(candidate)}
                className="text-xl font-semibold text-gray-900"
              >
                {candidate.name}
              </button>
            </div>
            <div className="text-base font-normal text-gray-900">
              {candidate.description.length > 200 ? (
                <DescriptionExpandable description={candidate.description} />
              ) : (
                candidate.description
              )}
            </div>
            <div>
              <p>
                Location:{' '}
                <span className="font-medium">{candidate.location}</span>
              </p>
              <p>
                Department:{' '}
                <span className="font-medium">{candidate.department}</span>
              </p>
            </div>
            <div className="text-base font-normal text-gray-500">
              {moment(candidate.date).fromNow()}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-3 self-start ">
          {true ? (
            <div className="flex flex-col items-end gap-2">
              <Badge
                color="green"
                className="w-fit text-xs font-medium"
                size="sm"
              >
                The meeting was called
              </Badge>
              <Badge
                color="green"
                className="w-fit text-xs font-medium"
                size="sm"
              >
                {candidateMeeting()}
              </Badge>
            </div>
          ) : (
            <button
              className=" overflow-none text-ellipsis whitespace-nowrap rounded-lg border border-red-500 bg-red-500 px-4 py-2.5 text-sm font-semibold text-white"
              onClick={() => setShowScheduleInterview(true)}
            >
              Schedule an meeting
            </button>
          )}{' '}
          <BurgerMenuButton />
        </div>
      </div>
    </>
  );
};
