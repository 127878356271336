import {
  Button,
  Card,
  Divider,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextInput,
  Text,
} from '@tremor/react';
import AccountView from './views/AccountView';
import {
  RiDriveFill,
  RiFacebookFill,
  RiNotionFill,
  RiSlackFill,
  RiDropboxFill,
  RiTeamFill,
  RiPenNibFill,
  RiDeleteBinLine,
  RiPencilFill,
} from '@remixicon/react';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import classNames from 'classnames';
import { useState, useEffect, useMemo, useRef } from 'react';
import EditJobTitle from './components/EditJobTitle';

const workspaces = [
  {
    workspace: 'sales_by_day_api',
    owner: 'John Doe',
    status: 'live',
    costs: '$3,509.00',
    region: 'US-West 1',
    capacity: '99%',
    lastEdited: '23/09/2023 13:00',
  },
  {
    workspace: 'marketing_campaign',
    owner: 'Jane Smith',
    status: 'live',
    costs: '$5,720.00',
    region: 'US-East 2',
    capacity: '80%',
    lastEdited: '22/09/2023 10:45',
  },
  {
    workspace: 'sales_campaign',
    owner: 'Jane Smith',
    status: 'live',
    costs: '$5,720.00',
    region: 'US-East 2',
    capacity: '80%',
    lastEdited: '22/09/2023 10:45',
  },
  {
    workspace: 'development_env',
    owner: 'Mike Johnson',
    status: 'live',
    costs: '$4,200.00',
    region: 'EU-West 1',
    capacity: '60%',
    lastEdited: '21/09/2023 14:30',
  },
  {
    workspace: 'new_workspace_1',
    owner: 'Alice Brown',
    status: 'live',
    costs: '$2,100.00',
    region: 'US-West 2',
    capacity: '75%',
    lastEdited: '24/09/2023 09:15',
  },
  {
    workspace: 'test_environment',
    owner: 'David Clark',
    status: 'inactive',
    costs: '$800.00',
    region: 'EU-Central 1',
    capacity: '40%',
    lastEdited: '25/09/2023 16:20',
  },
  {
    workspace: 'analytics_dashboard',
    owner: 'Sarah Wilson',
    status: 'live',
    costs: '$6,500.00',
    region: 'US-West 1',
    capacity: '90%',
    lastEdited: '26/09/2023 11:30',
  },
  {
    workspace: 'research_project',
    owner: 'Michael Adams',
    status: 'live',
    costs: '$3,900.00',
    region: 'US-East 1',
    capacity: '70%',
    lastEdited: '27/09/2023 08:45',
  },
  {
    workspace: 'training_environment',
    owner: 'Laura White',
    status: 'live',
    costs: '$2,500.00',
    region: 'EU-North 1',
    capacity: '55%',
    lastEdited: '28/09/2023 12:15',
  },
];

const data = [
  {
    name: 'Google Drive',
    description:
      'Automate your file upload workflow for documents and other assets',
    icon: RiDriveFill,
    status: 'Connected',
  },
  {
    name: 'Slack',
    description:
      "Process PTO, track leave balances, see who's out and look up employee details on Slack.",
    icon: RiSlackFill,
    status: 'Enable',
  },
  {
    name: 'Teams',
    description:
      'Introduce new hires, manage time off, and get your calendar summary on Teams.',
    icon: RiTeamFill,
    status: 'Enable',
  },
];

function IndeterminateCheckbox({ indeterminate, className, ...rest }: any) {
  const ref = useRef<any>(undefined);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames(
        'size-4 dark:border-dark-tremor-border dark:bg-dark-tremor-background dark:text-dark-tremor-brand dark:shadow-dark-tremor-input dark:focus:ring-dark-tremor-brand-muted rounded border-tremor-border text-tremor-brand shadow-tremor-input focus:ring-tremor-brand-muted',
        className,
      )}
      {...rest}
    />
  );
}

const JobTitles = () => {
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    // Pre-select the 2nd row for demo purpose
    setRowSelection({ 2: true });
  }, []);

  const workspacesColumns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }: any) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
            className="-translate-y-[1px]"
          />
        ),
        cell: ({ row }: any) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
            className="-translate-y-[1px]"
          />
        ),
        enableSorting: false,
        meta: { align: 'text-left' },
      },
      {
        header: 'Workspace',
        accessorKey: 'workspace',
        enableSorting: true,
        meta: { align: 'text-left' },
      },
      {
        header: 'Owner',
        accessorKey: 'owner',
        enableSorting: true,
        meta: { align: 'text-left' },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        meta: { align: 'text-left' },
      },
      {
        header: 'Region',
        accessorKey: 'region',
        enableSorting: false,
        meta: { align: 'text-left' },
      },
      {
        header: 'Capacity',
        accessorKey: 'capacity',
        enableSorting: false,
        meta: { align: 'text-left' },
      },
      {
        header: 'Costs',
        accessorKey: 'costs',
        enableSorting: false,
        meta: { align: 'text-right' },
      },
      {
        header: 'Last edited',
        accessorKey: 'lastEdited',
        enableSorting: false,
        meta: { align: 'text-right' },
      },
    ],
    [],
  );

  const [show, setShow] = useState(false);

  return (
    <div>
      <Button className="bg-red-500">Add Job Title</Button>
      <div>
        <Card className="mt-6 flex flex-row items-center justify-between">
          <Text>Accountant</Text>
          <div className="flex space-x-2">
            <RiPencilFill className="size-6 shrink-0" />
            <RiDeleteBinLine className="size-6 shrink-0" />
          </div>
        </Card>
        <Card className="mt-6 flex flex-row items-center justify-between">
          <Text>Account Executive</Text>
          <div className="flex space-x-2">
            <RiPencilFill className="size-6 shrink-0" />
            <RiDeleteBinLine className="size-6 shrink-0" />
          </div>
        </Card>
      </div>
    </div>
  );
};

const Integrations = () => {
  return (
    <>
      <dl className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {data.map((item) => (
          <Card key={item.name} className="flex flex-col justify-between">
            <item.icon className="size-6 shrink-0" aria-hidden={true} />
            <div className="mt-6 flex-1">
              <dt className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong">
                {item.name}
              </dt>
              <dd className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
                {item.description}
              </dd>
            </div>
            <button
              type="button"
              className="dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis disabled:hover:dark:bg-dark-tremor-brand mt-8 w-full whitespace-nowrap rounded-tremor-small bg-tremor-brand py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-tremor-brand"
              disabled={item.status === 'Connected' ? true : false}
            >
              {item.status}
            </button>
          </Card>
        ))}
      </dl>
    </>
  );
};

export function SettingsRoute() {
  return (
    // 'use client';
    <>
      <Card>
        <h3 className="dark:text-dark-tremor-content-strong text-tremor-title font-bold text-tremor-content-strong">
          Settings
        </h3>
        <p className="dark:text-dark-tremor-content mt-2 text-tremor-default leading-6 text-tremor-content">
          Manage your personal details, workspace governance and notifications.
        </p>
        <TabGroup className="mt-6">
          <TabList>
            <Tab>Workspace Details</Tab>
            <Tab>Integrations</Tab>
            {/* <Tab>Policies</Tab> */}
            <Tab>Job Titles</Tab>
          </TabList>
          {/* Content below only for demo purpose placed outside of <Tab> component. Add <TabPanels>, <TabPanel> to make it functional and to add content for other tabs */}
          <TabPanels>
            <TabPanel>
              <AccountView />
            </TabPanel>
            <TabPanel>
              <Integrations />
            </TabPanel>
            <TabPanel></TabPanel>
            <TabPanel>
              <JobTitles />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Card>
    </>
  );
}
