import axios from 'axios';
import { Dialog } from 'evergreen-ui';
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '../../../../assets/TrashIcon';
import { VacancyType } from '../../../../mirage/types';
import { useToast } from '../../../../utils/useToast';

type Props = {
  isOpen: boolean;
  onClose(): void;
  vacancy: VacancyType;
};

export const DeleteDialog = ({ isOpen, onClose, vacancy }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  const handleDeleteVacancy = async (id: number | string) => {
    setIsDeleting(true);
    await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/v1/vacancy/${id}`,
    });
    toast({
      title: 'Vacancy successfully deleted!',
      description: `${vacancy.title} vacancy deleted from your job list.`,
      variant: 'success',
    });
    setIsDeleting(false);
    onClose();
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] border border-gray-200 bg-white p-3 shadow-sm">
            <TrashIcon className="h-6 w-6" />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">
            Are you sure you want to delete {vacancy.title} vacancy?
          </div>
          <div className="text-sm font-normal text-[#475467]">
            This vacancy will be removed from your list, these changes cannot be
            undone, are you sure?
          </div>
        </div>

        <div className="mb-6 mt-8 flex flex-row justify-between gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-red-500 bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={() => handleDeleteVacancy(vacancy?.id)}
            disabled={isDeleting}
          >
            Delete
          </button>
        </div>
      </div>
    </Dialog>
  );
};
