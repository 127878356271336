import { CandidateType } from '../../../../mirage/types';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon, Menu, Popover } from 'evergreen-ui';
import { useToast } from '../../../../utils/useToast';
import { ScheduleInterviewDialog } from '../Dialogs/ScheduleInterviewDialog';
import { useState } from 'react';
import { RiFile2Line } from '@remixicon/react';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PostJobDialog } from '../Dialogs/PostJobDialog';

export const SavedCandidatesTable = ({
  candidates,
  showUser,
}: {
  candidates: CandidateType[];
  showUser: (candidate: CandidateType) => void;
}) => {
  const { toast } = useToast();
  const [showScheduleInterviewDialog, setShowScheduleInterview] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<CandidateType>(candidates[0]);
  const [addDialogShow, setAddDialogShow] = useState<boolean>(false);

  const getDate = (date: string) => {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <>
      <ScheduleInterviewDialog
        isOpen={showScheduleInterviewDialog}
        onClose={() => setShowScheduleInterview(false)}
        user={currentUser}
      />
      <PostJobDialog
        isOpen={addDialogShow}
        onClose={() => setAddDialogShow(false)}
      />
      <table className="min-w-full">
        <thead className="w-full border-b border-gray-200 bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3 pl-4 pr-3 text-left text-xs font-medium text-gray-600 sm:pl-6"
            >
              Name
            </th>

            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-600"
            >
              Status
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-600"
            >
              Position
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-600"
            >
              Resume
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-600"
            >
              Saved time
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-xs font-medium text-gray-600"
            >
              Location
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {candidates.map((user: CandidateType) => (
            <tr key={user.id}>
              <td className="w-full whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="flex items-center">
                  <div className="ml-4 cursor-pointer">
                    <div className="font-medium text-gray-900">{user.name}</div>
                  </div>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="w-fit rounded-2xl bg-orange-100 py-[2px] pl-1.5 pr-2 text-[#344054]">
                  <div className="flex items-center gap-1 text-orange-500">
                    <div className="h-2 w-2 rounded-full bg-orange-500"></div>
                    {user.status}
                  </div>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {user.location}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="flex w-fit flex-row items-center gap-2 rounded-xl p-4">
                  <div className="rounded-full bg-red-100 p-2">
                    <RiFile2Line className="text-red-500" />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="text-sm font-medium text-gray-700">
                      {user.resume.title}
                    </div>
                    <div className="text-sm font-normal text-gray-600">
                      {user.resume.url}
                    </div>
                  </div>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {getDate(new Date().toISOString())}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {user.location}
              </td>
              <td className="w-[120px] whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                <div
                  className="flex  items-end justify-end gap-4 p-2.5"
                  onClick={() => setCurrentUser(user)}
                >
                  <Popover
                    content={
                      <Menu>
                        <Menu.Group>
                          <Menu.Item onSelect={() => setAddDialogShow(true)}>
                            <div className="flex w-fit flex-row flex-nowrap text-gray-600">
                              <EnvelopeIcon className="mr-2 w-4" />
                              Send offer
                            </div>
                          </Menu.Item>
                          <Menu.Item onSelect={() => showUser(user)}>
                            <div className="flex flex-row flex-nowrap text-gray-600">
                              <EyeIcon className="mr-2 w-4" />
                              Detail
                            </div>
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer" />
                  </Popover>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
