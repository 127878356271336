import { RiArrowLeftLine, RiSearchLine } from '@remixicon/react';
import { Button, TextInput } from '@tremor/react';
import { VacanciesList } from './Vacancies/VacanciesList';
import axios from 'axios';
import { useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { isArray } from 'lodash';
import { VacancyType } from '../../../mirage/types';
import CreateNewVacancy from './Vacancies/CreateVacancy';
import { useQuery } from 'react-query';

const { REACT_APP_API_HOST } = process.env;

const vacancies: VacancyType[] = [
  {
    id: 1,
    title: 'Front-End Developer',
    description:
      'Develop and maintain the front-end of our web applications using React.js.',
    location: 'San Francisco, CA',
    type: ['full-time', 'entry level'],
    platform: 'LinkedIn',
    date: '2024-08-01T17:20:00',
    views: 150,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Research & Development',
  },
  {
    id: 2,
    title: 'Back-End Developer',
    description:
      'Work on server-side logic, database integration, and API development using Node.js.',
    location: 'New York, NY',
    type: ['full-time', 'mid level'],
    platform: 'LinkedIn',
    date: '2024-07-02T20:00:00',
    views: 200,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Reserch & Development',
  },
  {
    id: 3,
    title: 'Data Scientist',
    description:
      'Analyze complex data sets to help drive business decisions using Python and R.',
    location: 'Remote',
    type: ['contract', 'senior level'],
    platform: 'LinkedIn',
    date: '2024-08-01T12:14:00',
    views: 300,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Reserch & Development',
  },
  {
    id: 4,
    title: 'DevOps Engineer',
    description:
      'Implement and manage CI/CD pipelines, and maintain cloud infrastructure.',
    location: 'Austin, TX',
    type: ['full-time', 'mid level'],
    platform: 'LinkedIn',
    date: '2024-07-30T15:30:00',
    views: 180,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Reserch & Development',
  },
  {
    id: 5,
    title: 'UX/UI Designer',
    description:
      'Design intuitive user interfaces and improve user experience for our web and mobile apps.',
    location: 'Chicago, IL',
    type: ['part-time', 'junior level'],
    platform: 'LinkedIn',
    date: '2024-08-01T17:00:00',
    views: 250,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Reserch & Development',
  },
  {
    id: 6,
    title: 'Full-Stack Developer',
    description:
      'Build and maintain web applications with a focus on both front-end and back-end technologies.',
    location: 'Los Angeles, CA',
    type: ['full-time', 'mid level'],
    platform: 'LinkedIn',
    date: '2024-07-31T17:00:00',
    views: 220,
    platformLogo: 'https://cdn-icons-png.flaticon.com/512/174/174857.png',
    department: 'Reserch & Development',
  },
];

export const YourVacancies = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateMenu, setShowCreateMenu] = useState(false);

  const showCreateMenuFunc = () => {
    setShowCreateMenu(true);
  };

  const { data: response = [] } = useQuery({
    queryKey: ['vacancies'],
    queryFn: async () => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/ats/vacancy`,
        params: { status: 'VACANCY_ACTIVE' },
      });

      return data;
    },
  });

  const fuse = useMemo(() => {
    return new Fuse(response?.data as VacancyType[], {
      keys: [
        'title',
        'description',
        'location',
        'type',
        'platform',
        'department',
      ],
      threshold: 0.3,
    });
  }, [vacancies]);

  const filteredVacancies: VacancyType[] = useMemo(() => {
    if (!searchTerm) {
      return response?.data as VacancyType[];
    }
    return fuse.search(searchTerm).map((result) => result.item);
  }, [searchTerm, fuse]);

  return (
    <>
      {showCreateMenu ? (
        <div>
          <Button
            icon={RiArrowLeftLine}
            onClick={() => setShowCreateMenu(false)}
            className="mb-4 border-gray-300 bg-white text-gray-900"
            iconPosition="left"
            variant="secondary"
          >
            Back to Page
          </Button>
          <CreateNewVacancy />
        </div>
      ) : (
        <div className="rounded-xl bg-white shadow">
          <div className="flex flex-row items-center justify-between px-6 py-5">
            <div>
              <div className="text-lg font-semibold text-gray-900">
                Your vacancies
              </div>
              <div className="text-sm font-normal text-gray-600">
                All jobs you have published
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <TextInput
                icon={RiSearchLine}
                placeholder="Search..."
                className="w-auto lg:w-[400px]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className=" rounded-lg bg-red-500 px-4 py-2.5 font-semibold text-white"
                onClick={showCreateMenuFunc}
              >
                Create Vacancy
              </button>
            </div>
          </div>
          <div className="h-[1px] !min-w-full bg-gray-300 p-0"></div>
          <div>
            {filteredVacancies?.length === 0 || !isArray(filteredVacancies) ? (
              <div className="flex h-80 w-full items-center justify-center text-xl font-bold text-gray-900">
                No vacancies were found
              </div>
            ) : (
              <VacanciesList vacancies={filteredVacancies} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
