import { EnvelopeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Pill } from 'evergreen-ui';
import { RiFile2Line } from '@remixicon/react';
import { Button, Dialog, DialogPanel } from '@tremor/react';

export interface ResumeCompProps {
  candidateData:
    | {
        id?: number | string;
        profilePicture?: string;
        cvFilename?: string;
        cvPath?: string;
        cvFilesize?: string;
        jobTitle?: string;
        fullname?: string;
        location?: string;
        description?: string;
        position?: string;
        skills?: string[];
        date?: string;
        platform?: string;
        platformLogo?: string;
        department?: string;
        planMeeting?: string;
      }
    | undefined;
}

const ResumeCard: React.FC<ResumeCompProps> = ({ candidateData }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="mx-auto rounded-lg bg-white p-6 shadow-md">
      <header className="mb-4 flex border-b pb-4">
        <div>
          <p className="text-sm text-gray-500">Resume from August 1, 2024</p>
          <h1 className="text-2xl font-bold">{candidateData?.fullname}</h1>
          <h2 className="mt-16 text-xl font-semibold">
            {candidateData?.department}
          </h2>
        </div>
        <div className="ml-auto mt-6 flex h-fit justify-end space-x-2">
          <button className=" overflow-none text-ellipsis whitespace-nowrap rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-semibold text-gray-900">
            Save
          </button>
          <button className=" flex w-fit rounded-lg border border-red-500 bg-red-500 px-4 py-2.5 text-sm font-semibold text-white">
            <EnvelopeIcon className="my-auto mr-1 h-4" /> Offer a vacancy
          </button>
        </div>
      </header>
      <section className="mb-6">
        <ul className="list-none space-y-1">
          <li>
            <span className="font-light">Employment:</span> Full-time,
            part-time.
          </li>
          <li>
            <span className="font-light">City of residence:</span>{' '}
            {candidateData?.location}
          </li>
          <li>
            <span className="font-light">I'm ready to work:</span>{' '}
            <button className="text-red-600">Remove</button>
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Contact information</h2>
        <ul className="list-none space-y-1">
          <li>
            <span className="font-light">Email:</span> asnow@gmail.com
          </li>
          <li>
            <span className="font-light">Phone number:</span> +32 (234) 123 123
            12
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Work experience</h2>
        <div className="space-y-4">
          <div>
            <p>Customer service</p>
            <p>from 06.2017 to 03.2020 (2 years 9 months)</p>
            <p>
              Holmdel Township, (New Jersey, USA) ) (Telecommunications andTemp
              network technologies)
            </p>
          </div>
          <div>
            <p>Customer service</p>
            <p>from 06.2017 to 03.2020 (2 years 9 months)</p>
            <p>
              Holmdel Township, (New Jersey, USA) ) (Telecommunications and
              network technologies)
            </p>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Education</h2>
        <p>University of Luxembourg</p>
        <p className="text-gray-600">Language studies, Luxembourg</p>
        <p className="text-sm text-gray-600">Sep 2014 - Jun 2017</p>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Skills</h2>
        <div className="flex space-x-2">
          {candidateData?.skills?.map((item) => {
            return (
              <Pill key={item} color="green">
                {item}
              </Pill>
            );
          })}
        </div>
      </section>
      <footer>
        {candidateData?.cvFilename && (
          <div>
            <h2 className="mb-2 text-xl font-semibold">Resume</h2>
            <Button
              className="block max-w-[330px] border-0 bg-white hover:bg-white"
              onClick={() => setIsOpen(true)}
            >
              <div className="flex w-[330px] flex-row items-center gap-2 rounded-xl border border-gray-600 p-4">
                <div className="rounded-full bg-red-100 p-2">
                  <RiFile2Line className="text-red-500" />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="text-sm font-medium text-gray-700">
                    {candidateData?.cvFilename}
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    {candidateData?.cvFilesize}
                  </div>
                </div>
              </div>
            </Button>
            <Dialog
              open={isOpen}
              onClose={(val) => setIsOpen(val)}
              static={true}
            >
              <DialogPanel>
                <div className="flex justify-between">
                  <h2 className="mb-2 text-xl font-semibold text-gray-900">
                    Resume
                  </h2>
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
                <div className="h-[400px] w-full bg-gray-300"></div>
              </DialogPanel>
            </Dialog>
          </div>
        )}
      </footer>
    </div>
  );
};

export default ResumeCard;
