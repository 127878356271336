import { Dialog } from 'evergreen-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { offerVacancy } from '../../../../network';
import * as yup from 'yup';
import { useToast } from '../../../../utils/useToast';
import { BriefcaseIcon } from '../../../../assets/BriefcaseIcon';
import { Tab, TabGroup, TabList } from '@tremor/react';
import { useState } from 'react';
import { CreateVacancy } from './CreateVacancy';
import { Templates } from './Templates';

type Props = {
  isOpen: boolean;
  onClose(): void;
  showSaveTemplate: () => void;
};

export const OfferVacancyDialog = ({
  isOpen,
  onClose,
  showSaveTemplate,
}: Props) => {
  const { mutateAsync } = useMutation(offerVacancy);
  const [openedWindow, setOpenedWindow] = useState(1);
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      stage: 'Interview',
      from: [],
      subject: '',
      text: '',
      name: '',
    },
    validationSchema: yup.object({
      stage: yup.string().required('Stage is required'),
      from: yup.array().min(1, 'At least one email is required').required(),
      subject: yup.string().required('Subject is required'),
      text: yup.string().required('Text is required'),
      name: yup.string().when('saveAsTemplate', {
        is: true,
        then: yup.string().required('Template name is required'),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: async (values) => {
      await mutateAsync(values);
      toast({
        variant: 'success',
        title: 'Offer sent.',
      });
      closeDialog();
    },
  });

  const closeDialog = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
      preventBodyScrolling
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="rounded-[10px] border border-gray-200 bg-[#FEE9E9] p-3 shadow-sm">
            <BriefcaseIcon />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={closeDialog} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">Offer a vacancy</div>
          <p className="text-sm text-gray-500">
            Your new project has been created. Invite colleagues to collaborate
            on this project.
          </p>
        </div>
        <TabGroup defaultIndex={openedWindow - 1} className="w-full">
          <TabList
            color="red-500"
            className="w-full border-[1px] border-gray-200 bg-white"
            variant="solid"
          >
            <Tab
              className={`my-[2px] flex w-1/2 items-center justify-center text-gray-300 ${
                openedWindow === 1 ? 'font-bold text-red-500' : ''
              }`}
              value="1"
              onClick={() => setOpenedWindow(1)}
            >
              Create new
            </Tab>
            <Tab
              className={`my-[2px] flex w-1/2 items-center justify-center text-gray-300 ${
                openedWindow === 2 ? 'font-bold text-red-500' : ''
              }`}
              value="2"
              onClick={() => setOpenedWindow(2)}
            >
              Templates
            </Tab>
          </TabList>
        </TabGroup>
        {openedWindow === 1 ? (
          <CreateVacancy
            formik={formik}
            closeDialog={closeDialog}
            showSaveTemplate={showSaveTemplate}
          />
        ) : (
          <Templates formik={formik} closeDialog={closeDialog} />
        )}
      </div>
    </Dialog>
  );
};
