import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from '../types';

const { REACT_APP_API_HOST } = process.env;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_HOST,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('x-auth-token') as string;
      headers.set('authorization', `Bearer ${token}`);
    },
  }),
  endpoints: (builder) => ({
    fetchUsers: builder.query<User[], void>({
      query: () => '/v1/people',
      transformResponse: ({ people }) => {
        return people;
      },
    }),
    getUser: builder.query<User, { id?: string }>({
      query: (params) => {
        if (typeof params.id === 'undefined') {
          return `/v1/people`;
        }

        return `/v1/people/${params.id}`;
      },
      transformResponse: ({ user }) => {
        return user;
      },
    }),
    getAuxillaryInformation: builder.query<any, void>({
      query: () => '/v1/users/auxiliary',
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useGetUserQuery,
  useGetAuxillaryInformationQuery,
} = userApi;
