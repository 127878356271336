import axios from 'axios';
import { useQuery } from 'react-query';

const { REACT_APP_API_HOST } = process.env;

export function useRoles() {
  return useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const { data: response = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/role`,
      });

      return response?.data;
    },
  });
}

export function useTeams() {
  return useQuery({
    queryKey: ['teams'],
    queryFn: async () => {
      const { data: response = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/team`,
      });
      console.log('RESPONSE FROM SeRVER ');
      console.log(response.data);
      return response?.data;
    },
  });
}

export function useOffices() {
  return useQuery({
    queryKey: ['offices'],
    queryFn: async () => {
      const { data: response = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/office`,
      });

      return response?.data;
    },
  });
}

export function usePeople() {
  return useQuery({
    queryKey: ['people'],
    queryFn: async () => {
      const { data: response = [] } = await axios({
        method: 'get',
        url: `${REACT_APP_API_HOST}/v1/people`,
        params: {
          page: 0,
          limit: 100,
        },
      });

      return response || [];
    },
  });
}
