import { ChevronLeftIcon, ChevronRightIcon } from 'evergreen-ui';
import React from 'react';

interface PaginationProps {
  limit: number;
  page: number;
  count: number;
  onPageChange: (newPage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  limit,
  page,
  count,
  onPageChange,
}) => {
  const totalPages = Math.ceil(count / limit) || 0;

  const handlePreviousPage = () => {
    if (page > 0) {
      onPageChange(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      onPageChange(page + 1);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePreviousPage}
          disabled={page === 0}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === 0 ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-50'
          }`}
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages - 1}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === totalPages - 1
              ? 'cursor-not-allowed opacity-50'
              : 'hover:bg-gray-50'
          }`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{page * limit + 1}</span> to{' '}
            {count && (
              <span className="font-medium">
                {Math.min((page + 1) * limit, count)}
              </span>
            )}{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex gap-4 -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePreviousPage}
              disabled={page === 0}
              className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 ${
                page === 0
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-gray-50'
              }`}
            >
              <span className="px-2 text-gray-900">Previous</span>
            </button>

            <button
              onClick={handleNextPage}
              disabled={page === totalPages - 1}
              className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 ${
                page === totalPages - 1
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-gray-50'
              }`}
            >
              <span className="px-2 text-gray-900">Next</span>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
