import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Pill } from 'evergreen-ui';
import moment from 'moment';
import { VacancyType } from '../../../../mirage/types';
import { DeleteDialog } from '../Dialogs/DeleteDialog';
import { useState } from 'react';
import EditVacancy from './EditVacancy';
import { RiArrowLeftLine } from '@remixicon/react';
import { Badge, Button } from '@tremor/react';

export const VacancyItem = ({ vacancy }: { vacancy: VacancyType }) => {
  const [showCurrentUser, setShowCurrentUser] = useState(false);

  const showUser = () => {
    setShowCurrentUser(true);
  };

  const formatVacancyDate = (dateString: string) => {
    const calendarDate = moment(dateString).calendar(null, {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastWeek: '[last] dddd',
      nextWeek: 'dddd',
      sameElse: 'L',
    });
    const time = moment(dateString).format('hh:mm A');
    return `${calendarDate}, ${time}`;
  };
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  return (
    <>
      {showCurrentUser ? (
        <div>
          <Button
            icon={RiArrowLeftLine}
            onClick={() => setShowCurrentUser(false)}
            className="mb-4 border-gray-300 bg-white text-gray-900"
            iconPosition="left"
            variant="secondary"
          >
            Back to Vacancy
          </Button>
          <EditVacancy vacancy={vacancy} />
        </div>
      ) : (
        <div>
          <DeleteDialog
            isOpen={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            vacancy={vacancy}
          />
          <div className="flex flex-row justify-between gap-8 rounded-lg bg-white p-6 shadow ring-1 ring-black/5">
            <div className="flex w-full flex-col gap-3">
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-3">
                  <Badge color="yellow">
                    {formatVacancyDate(vacancy.date)}
                  </Badge>
                  <Badge color="yellow">{vacancy.views}</Badge>
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <div className="text-xl font-semibold text-gray-900">
                  {vacancy.title}
                </div>
                <div className="text-base font-normal text-gray-500">
                  {vacancy.description}
                </div>
                <p>
                  Location:{' '}
                  <span className="font-medium">{vacancy.location}</span>
                </p>
                <p>
                  Department:{' '}
                  <span className="font-medium">{vacancy.department}</span>
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-3 self-start">
              <button
                className="flex h-fit flex-nowrap rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-semibold text-gray-700"
                onClick={() => showUser()}
              >
                <PencilSquareIcon className="my-auto mr-1 h-4" />
                Edit
              </button>
              <button
                className="flex h-fit flex-nowrap rounded-lg border border-red-500 bg-white px-4 py-2.5 text-sm font-semibold text-red-500"
                onClick={() => setShowDeleteDialog(true)}
              >
                <TrashIcon className="my-auto mr-1 h-4 text-red-500" />
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
