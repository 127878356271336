import React from 'react';
import moment, { Moment } from 'moment';
import classnames from 'classnames';

import { Event } from '../../../../../../types';
import { MobileWeekCalendarView } from './MobileWeekCalendarView';

interface IProps {
  events: Event[];
  today: Moment;
}

export function DesktopWeekCalendarView({ events, today }: IProps) {
  const startDay = today.clone().startOf('week').startOf('day');
  const day = startDay.clone().subtract(1, 'day');
  const daysMap = [...Array(7)].map(() => day.add(1, 'day').clone());
  let col: number[] = [];
  let row: number[] = [];

  return (
    <div className="isolate flex flex-auto flex-col overflow-auto bg-white">
      <div className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
        <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
          <MobileWeekCalendarView daysMap={daysMap} />
          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14" />
            {daysMap.map((day, index) => (
              <div
                className="flex items-center justify-center py-3"
                key={index}
              >
                <span className="contents whitespace-pre">
                  {day.format('dddd').substring(0, 3)}{' '}
                  <span
                    className={classnames(
                      moment().isSame(day, 'day') &&
                        'ml-1.5 flex h-8 w-8 rounded-full bg-red-600 text-white',
                      'items-center justify-center font-semibold text-gray-900',
                    )}
                  >
                    {day.format('D')}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-auto">
          <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
              style={{
                gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))',
              }}
            >
              <div className="row-end-1 h-7" />
              {[...new Array(24)].map((e, index) => (
                <React.Fragment key={index}>
                  <div>
                    <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      {`${index}`.padStart(2, '0')}:00
                    </div>
                  </div>
                  <div />
                </React.Fragment>
              ))}
            </div>

            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-8" />
            </div>

            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{
                gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto',
              }}
            >
              {daysMap.map((day, index) => (
                <React.Fragment key={index}>
                  {events
                    .filter((event) => day.isSame(event.start.date, 'day'))
                    .map((event, eventIndex) => {
                      const numCol = index + 1;
                      const hour = parseInt(
                        moment(event.start.date).format('h'),
                      );
                      const numRow = 2 + hour * 12;
                      if (col.includes(numCol) && row.includes(numRow)) {
                        return <li></li>;
                      }
                      col.push(numCol);
                      row.push(numRow);

                      return (
                        <li
                          key={eventIndex}
                          className={`col-start-${numCol} relative mt-px flex${day.isoWeekday()}`}
                          style={{ gridRow: `${numRow} / span 12` }}
                        >
                          <a
                            href="/"
                            className="bg-blue-50 hover:bg-blue-100 group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5"
                          >
                            <p className="text-blue-700 order-1 font-semibold">
                              {event.title}
                            </p>
                            <p className="text-blue-500 group-hover:text-blue-700">
                              <time
                                dateTime={moment(event.start.date).format(
                                  'h:mm A',
                                )}
                              >
                                {moment(event.start.date).format('h:mm A')}
                              </time>
                            </p>
                          </a>
                        </li>
                      );
                    })}
                </React.Fragment>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
