import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const { REACT_APP_API_HOST } = process.env;

export const DecisionConfirmationRoute = () => {
  const params = useParams();

  const { data } = useQuery({
    queryKey: ['home-calendar', params.id],
    queryFn: async (options) => {
      const { queryKey } = options;
      const [key, auth] = queryKey;

      const { data = [] } = await axios({
        method: 'put',
        url: `${REACT_APP_API_HOST}/v1/timeaway/${params.id}/${params.decision}`,
      });

      return data;
    },
  });

  const renderDecision = () => {
    switch (params.decision) {
      case 'approve':
        return {
          title: 'Request Approved',
          message: 'This time away request has been approved!',
        };

      case 'decline':
        return {
          title: 'Request Declined',
          message: 'This time away request has been declined.',
        };

      default:
        return {
          title: 'Request Pending',
          message: 'This time away request is pending approval.',
        };
    }
  };

  const { title, message } = renderDecision();

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <div className="container mx-auto p-4">
          <header className="mb-6 flex items-center justify-between">
            <img
              src="/redstone-full-white.png"
              alt="Company Logo"
              className="h-12"
            />
            <nav>
              <ul className="flex space-x-4">
                <li>
                  <Link className="text-blue-500" to="/profile">
                    My Requests
                  </Link>
                </li>
              </ul>
            </nav>
          </header>

          <h1 className="mb-4 text-2xl font-bold">{title}</h1>
          <p className="mb-6">{message}</p>

          <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <h2 className="mb-2 text-xl font-bold">Request Details</h2>
              <p>
                <strong>Employee Name:</strong> {data?.timeaway?.applicant}
              </p>
              <p>
                <strong>Date Submitted:</strong>{' '}
                {data?.timeaway?.date_submitted}
              </p>
              <p>
                <strong>Requested Dates:</strong> {data?.timeaway?.start_date} -{' '}
                {data?.timeaway?.end_date}
              </p>
              <p>
                <strong>Total Days:</strong> {data?.timeaway?.days_requested}
              </p>
              <p>
                <strong>Reason:</strong> {data?.timeaway?.type}
              </p>
            </div>
            <div>
              <h2 className="mb-2 text-xl font-bold">Approval Details</h2>
              <p>
                <strong>Approved by:</strong> {data?.timeaway?.decision_by}
              </p>
              <p>
                <strong>Date Approved:</strong> {data?.timeaway?.date_approved}
              </p>
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className="bg-blue-500 rounded px-4 py-2 text-white"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>

          <footer className="mt-12 text-center">
            <p>For support, contact us at support@redstonehr.com.</p>
          </footer>
        </div>
      </div>
    </div>
  );
};
