// 'use client';

import {
  Divider,
  NumberInput,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@tremor/react';
import { useState, useEffect } from 'react';

// Example data fetching function (replace with actual API calls or database queries)
async function fetchAbsencePolicies(): Promise<any[]> {
  return [
    { id: 1, name: 'Sick Leave', days: 10 },
    { id: 2, name: 'Paid Time Off (PTO)', days: 15 },
    { id: 3, name: 'Parental Leave', days: 30 },
  ];
}

// Example data update function (replace with actual API calls or database operations)
async function saveAbsencePolicy(newPolicy: any) {
  // Save policy to database (via API or direct connection)
  console.log('Saving policy:', newPolicy);
}

export default function Example() {
  const [absencePolicies, setAbsencePolicies] = useState<any[]>([]);
  const [newPolicy, setNewPolicy] = useState({ name: '', days: 0 });

  // Fetch existing absence policies when the component loads
  useEffect(() => {
    async function loadPolicies() {
      const policies = await fetchAbsencePolicies();
      setAbsencePolicies(policies);
    }
    loadPolicies();
  }, []);

  // Handle adding a new absence policy dynamically
  const handleAddPolicy = () => {
    const updatedPolicies = [...absencePolicies, newPolicy];
    setAbsencePolicies(updatedPolicies);
    setNewPolicy({ name: '', days: 0 });
    saveAbsencePolicy(newPolicy); // Save to database
  };

  return (
    <div className="py-8">
      <form>
        {/* Organization Setup Section */}
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
          <div>
            <h2 className="dark:text-dark-tremor-content-strong font-semibold text-tremor-content-strong">
              Organization Information
            </h2>
            <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
              Enter details about your organization.
            </p>
          </div>
          <div className="sm:max-w-3xl md:col-span-2">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
              <div className="col-span-full sm:col-span-3">
                <label
                  htmlFor="company-name"
                  className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                >
                  Company Name
                </label>
                <TextInput
                  type="text"
                  id="company-name"
                  name="company-name"
                  placeholder="Company Name"
                  className="mt-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label
                  htmlFor="industry"
                  className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                >
                  Industry
                </label>
                <TextInput
                  type="text"
                  id="industry"
                  name="industry"
                  placeholder="e.g., Healthcare, Retail"
                  className="mt-2"
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="employee-count"
                  className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                >
                  Number of Employees
                </label>
                <NumberInput
                  id="employee-count"
                  name="employee-count"
                  placeholder="e.g., 100"
                  enableStepper={false}
                  className="mt-2"
                />
              </div>
            </div>
          </div>
        </div>
        <Divider className="my-14" />

        {/* Absence Policy Section */}
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
          <div>
            <h2 className="dark:text-dark-tremor-content-strong font-semibold text-tremor-content-strong">
              Absence Policies
            </h2>
            <p className="dark:text-dark-tremor-content mt-1 text-tremor-default leading-6 text-tremor-content">
              Define and manage your company's absence policies.
            </p>
          </div>
          <div className="sm:max-w-3xl md:col-span-2">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
              {/* Dynamically render existing absence policies from the database */}
              {absencePolicies.map((policy: any) => (
                <div key={policy.id} className="col-span-full sm:col-span-3">
                  <label
                    htmlFor={`policy-${policy.id}`}
                    className="dark:text-dark-tremor-content-strong text-tremor-default font-medium text-tremor-content-strong"
                  >
                    {policy.name}
                  </label>
                  <NumberInput
                    id={`policy-${policy.id}`}
                    name={`policy-${policy.id}`}
                    placeholder={policy.days.toString()}
                    enableStepper={false}
                    className="mt-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Divider className="my-14" />

        {/* Save/Submit Buttons */}
        <div className="flex items-center justify-end space-x-4">
          <button
            type="button"
            className="dark:text-dark-tremor-content-strong whitespace-nowrap rounded-tremor-small px-4 py-2.5 text-tremor-default font-medium text-tremor-content-strong"
          >
            Go back
          </button>
          <button
            type="submit"
            className="dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis whitespace-nowrap rounded-tremor-default bg-tremor-brand px-4 py-2.5 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
          >
            Save settings
          </button>
        </div>
      </form>
    </div>
  );
}
