import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { VacancyType } from '../../../../mirage/types';

const EditVacancy = ({ vacancy }: { vacancy: VacancyType }) => {
  return (
    <div className="mx-auto rounded-lg bg-white p-6 shadow-md">
      <header className="mb-4 flex border-b pb-4">
        <div>
          <p className="text-sm text-gray-500">Resume from August 1, 2024</p>
          <h2 className="mt-16 text-xl font-semibold">{vacancy?.department}</h2>
        </div>
        <div className="ml-auto mt-6 flex h-fit justify-end space-x-2">
          <button className=" overflow-none text-ellipsis whitespace-nowrap rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-sm font-semibold text-gray-900">
            Save
          </button>
          <button className=" flex w-fit rounded-lg border border-red-500 bg-red-500 px-4 py-2.5 text-sm font-semibold text-white">
            <EnvelopeIcon className="my-auto mr-1 h-4" /> Offer a vacancy
          </button>
        </div>
      </header>
      <section className="mb-6">
        <ul className="list-none space-y-1">
          <li>
            <span className="font-light">Employment:</span> Full-time,
            part-time.
          </li>
          <li>
            <span className="font-light">City of residence:</span>{' '}
            {vacancy?.location}
          </li>
          <li>
            <span className="font-light">I'm ready to work:</span>{' '}
            <button className="text-red-600">Remove</button>
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Contact information</h2>
        <ul className="list-none space-y-1">
          <li>
            <span className="font-light">Email:</span> asnow@gmail.com
          </li>
          <li>
            <span className="font-light">Phone number:</span> +32 (234) 123 123
            12
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Work experience</h2>
        <div className="space-y-4">
          <div>
            <p>Customer service</p>
            <p>from 06.2017 to 03.2020 (2 years 9 months)</p>
            <p>
              Holmdel Township, (New Jersey, USA) ) (Telecommunications andTemp
              network technologies)
            </p>
          </div>
          <div>
            <p>Customer service</p>
            <p>from 06.2017 to 03.2020 (2 years 9 months)</p>
            <p>
              Holmdel Township, (New Jersey, USA) ) (Telecommunications and
              network technologies)
            </p>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <h2 className="mb-2 text-xl font-semibold">Education</h2>
        <p>University of Luxembourg</p>
        <p className="text-gray-600">Language studies, Luxembourg</p>
        <p className="text-sm text-gray-600">Sep 2014 - Jun 2017</p>
      </section>
    </div>
  );
};

export default EditVacancy;
