import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import moment from 'moment';
import { AuthWrapper } from './app';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './app/ErrorBoundary';
import axios from 'axios';

import './index.css';
import { Toaster } from './app/components/Toaster';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // window.location.href = '/';
  },
);

moment.updateLocale('en', {
  week: { dow: 1 },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Toaster />
      <AuthWrapper></AuthWrapper>
    </ErrorBoundary>
  </Provider>,
);

reportWebVitals();
