import img from '../../../../assets/finished.svg';

export function FinalStep() {
  return (
    <div className="flex justify-center pb-[185px] sm:pb-0">
      <div className="mt-16 flex flex-col items-center px-4 text-center sm:mt-0 sm:px-0 lg:w-1/2">
        <img src={img} alt="final" className="h-12" />
        <p className="mt-4 text-[20px] font-semibold">
          You are ready to perform!
        </p>
        <p className="mt-4 text-[14px]">
          Thank you for taking the time to provide us with these details. Now
          your organization profile is active.
        </p>
        <button
          className="focus:shadow-outline mb-8 mt-10 w-full rounded bg-red-600 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none sm:mb-0"
          type="submit"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
