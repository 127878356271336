import { Position, Tooltip } from 'evergreen-ui';
import { UserType } from '../../../types';

type Props = {
  user: UserType;
};

export const UserPreview = ({ user }: Props) => {
  return (
    <Tooltip key={user.id} content={user.full_name} position={Position.TOP}>
      <a href={`/profile/${user.id}`} className="p-1">
        <img
          alt={user.full_name}
          src={user.safe_profile_picture_url}
          className="h-11 w-11 flex-none rounded-full bg-gray-100"
        />
      </a>
    </Tooltip>
  );
};
