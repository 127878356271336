import { Dialog } from 'evergreen-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { createJob } from '../../../../network';
import * as yup from 'yup';
import { FormGroup } from '../../../../ui/form/FormGroup';
import { Input } from '../../../../ui/form/Input';
import { Textarea } from '../../../../ui/form/Textarea';
import { Select } from '../../../../ui/form/Select';
import {
  SKILLS,
  WORKING_OPTIONS,
  WORKPLACE_TYPES,
} from '../../../../constants';
import { MultiSelect, MultiSelectItem } from '@tremor/react';
import { useToast } from '../../../../utils/useToast';
import { BriefcaseIcon } from '../../../../assets/BriefcaseIcon';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const PostJobDialog = ({ isOpen, onClose }: Props) => {
  const { mutateAsync } = useMutation(createJob);
  const { toast } = useToast();

  const formik = useFormik({
    initialValues: {
      jobTitle: '',
      description: '',
      jobLocation: '',
      website: '',
      jobType: 'Full-time',
      workplaceType: 'On-site',
      skills: [],
    },
    validationSchema: yup.object({
      jobTitle: yup.string().required('Job title is required'),
      description: yup.string().required('Description is required'),
      jobLocation: yup.string().required('Job location is required'),
      website: yup
        .string()
        .url('Enter a valid URL')
        .required('Website is required'),
      jobType: yup.string().required('Job type is required'),
      workplaceType: yup.string().required('Workplace type is required'),
      skills: yup
        .array()
        .min(1, 'Skills are required')
        .required('Skills are required'),
    }),
    onSubmit: async ({ ...restValues }) => {
      mutateAsync({ ...restValues });
      toast({
        variant: 'success',
        title: 'Job successfully posted.',
      });
      closeDialog();
    },
  });

  const closeDialog = () => {
    formik.resetForm();
    onClose();
  };

  const onSubmit = () => {
    formik.handleSubmit();
  };

  const handleSkillsChange = (selectedSkills: any) => {
    formik.setFieldValue('skills', selectedSkills);
  };

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={onClose}
      hasFooter={false}
      hasHeader={false}
      preventBodyScrolling
    >
      <div className="flex flex-col">
        <div className="mb-4 flex flex-row justify-between pt-6">
          <div className="shadow-none! rounded-full border-none bg-red-100 p-3">
            <BriefcaseIcon />
          </div>
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={closeDialog} />
        </div>

        <div className="mb-5 flex flex-col gap-1">
          <div className="text-lg font-semibold">Post a job</div>
        </div>

        <div className="space-y-4">
          <FormGroup label="Job title" htmlFor="jobTitle">
            <Input
              id="jobTitle"
              type="text"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
              placeholder="Enter job title"
              error={formik.errors.jobTitle}
            />
          </FormGroup>
          <FormGroup label="Description" htmlFor="description">
            <Textarea
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Enter a description..."
              rows={6}
              error={formik.errors.description}
            />
          </FormGroup>
          <FormGroup label="Job location" htmlFor="jobLocation">
            <Input
              id="jobLocation"
              type="text"
              name="jobLocation"
              value={formik.values.jobLocation}
              onChange={formik.handleChange}
              placeholder="Enter location"
              error={formik.errors.jobLocation}
            />
          </FormGroup>
          <FormGroup label="Website" htmlFor="website">
            <div className="flex">
              <input
                type="text"
                value="http://"
                readOnly
                className="w-[80px] rounded-l-md border border-r-0 border-gray-300 bg-gray-100 p-2 text-gray-500 focus:border-gray-300 focus:ring-gray-400"
              />
              <input
                type="text"
                id="website"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                placeholder="www.untitledui.com"
                className="block w-full rounded-r-md border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-sm font-normal text-gray-700 transition ease-in-out focus:border-red-600 focus:outline-none focus:ring-1 focus:ring-red-400"
              />
            </div>
            {formik.touched.website && formik.errors.website && (
              <div className="mt-1 rounded-sm bg-rose-600 px-1.5 py-0.5 text-xs text-white">
                {formik.errors.website}
              </div>
            )}
          </FormGroup>
          <FormGroup label="Job type" htmlFor="jobType">
            <Select
              id="jobType"
              name="jobType"
              value={formik.values.jobType}
              onChange={formik.handleChange}
              error={formik.errors.jobType}
            >
              {WORKING_OPTIONS.map((type) => {
                return (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup label="Workplace type" htmlFor="workplaceType">
            <Select
              error={formik.errors.workplaceType}
              id="workplaceType"
              name="workplaceType"
              value={formik.values.workplaceType}
              onChange={formik.handleChange}
            >
              {WORKPLACE_TYPES.map((type) => {
                return (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          <FormGroup label="Skills" htmlFor="skills">
            <MultiSelect
              value={formik.values.skills}
              onChange={handleSkillsChange}
              name="skills"
              id="skills"
              error={!!formik.errors.skills}
            >
              {SKILLS.map((skill) => {
                return (
                  <MultiSelectItem key={skill.value} value={skill.value}>
                    {skill.title}
                  </MultiSelectItem>
                );
              })}
            </MultiSelect>
          </FormGroup>
        </div>

        <div className="mb-6 mt-8 flex w-full flex-col-reverse gap-3">
          <button
            className="w-full rounded-lg border border-[#D0D5DD] bg-white px-[18px] py-[10px] text-base font-semibold text-[#344054] shadow-sm"
            onClick={closeDialog}
          >
            Cancel
          </button>
          <button
            className="w-full rounded-lg border border-red-500 bg-red-500 px-[18px] py-[10px] text-base font-semibold text-white shadow-sm disabled:bg-[#47546789]"
            onClick={onSubmit}
            type="submit"
          >
            Create
          </button>
        </div>
      </div>
    </Dialog>
  );
};
