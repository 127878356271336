import { SelectHTMLAttributes } from 'react';

import { CommonInputProps, BaseInput } from './BaseInput';

type Props = CommonInputProps & SelectHTMLAttributes<HTMLSelectElement>;

export function Select({ error, ...restProps }: Props) {
  return (
    <BaseInput error={error}>
      {(baseProps) => <select {...baseProps} {...restProps} />}
    </BaseInput>
  );
}
