import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const timeline = [
  {
    id: 1,
    content: 'Request sent for',
    target: 'Holiday Paid',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: UserIcon,
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: 'Approved by',
    target: 'Bethany Blake',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    icon: HandThumbUpIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: 'Confirmed holiday request for',
    target: 'Holiday (Paid)',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
];

const Panel = ({ children }: any) => {
  return (
    <>
      <div className="rounded-lg-white overflow-hidden bg-white px-4 py-4 shadow">
        {children}
      </div>
    </>
  );
};

const Timeline = () => {
  return (
    <div className="col-span-2">
      <Panel>
        <div className="flow-root">
          <ul role="list" className="-mb-8">
            {timeline.map((event, eventIdx) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  {eventIdx !== timeline.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          event.iconBackground,
                          'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                        )}
                      >
                        <event.icon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">
                          {event.content}{' '}
                          <a
                            href={event.href}
                            className="font-medium text-gray-900"
                          >
                            {event.target}
                          </a>
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={event.datetime}>{event.date}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Panel>
    </div>
  );
};

const DetailedInformation = () => {
  const { id } = useParams();

  const { REACT_APP_API_HOST } = process.env;
  const { data } = useQuery(['detailed-information'], async (options) => {
    const { queryKey } = options;
    const [key, auth] = queryKey;

    const { data } = await axios({
      method: 'get',
      url: `${REACT_APP_API_HOST}/v1/timeaway/${id}`,
    });

    return data?.timeaway;
  });

  const renderDecision = (decision: string) => {
    switch (decision) {
      case 'pending':
        return 'Pending';

      case 'approved':
        return 'Approved';

      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <div className="rounded-lg-white col-span-4 overflow-hidden bg-white shadow">
        <div className="px-8 py-8">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Request Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Time off request details and information.
            </p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Applicant
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data?.applicant}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Decision by
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data?.decision_by}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Request for
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data?.request_for}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Status
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {renderDecision(data?.status)}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Days requested
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {data?.days_requested}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Description
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  n/a
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

const Heading = () => {
  const { user, logout } = useAuth0();

  return (
    <div className="pb-5">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Timeaway for {user?.given_name} {user?.family_name}
      </h3>
    </div>
  );
};

function EventRoute() {
  return (
    <>
      <Heading />
      <div className="grid grid-cols-2 gap-4">
        <DetailedInformation />
        {/*<Timeline /> */}
      </div>
    </>
  );
}

export const ProtectedEventRoute = EventRoute;
