import React from 'react';

export interface Step {
  label: string;
  isActive: boolean;
  isCompleted: boolean;
}

interface StepperProps {
  steps: Step[];
}

const Stepper: React.FC<StepperProps> = ({ steps }) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step, index) => (
          <li key={step.label} className="md:flex-1">
            {step.isCompleted === true ? (
              <a
                href={'#'}
                className="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">
                  Step {index + 1}
                </span>
                <span className="text-sm font-medium">{step.label}</span>
              </a>
            ) : step.isActive === true ? (
              <a
                href={'#'}
                className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-indigo-600">
                  Step {index + 1}
                </span>
                <span className="text-sm font-medium">{step.label}</span>
              </a>
            ) : (
              <a
                href={'#'}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  Step {index + 1}
                </span>
                <span className="text-sm font-medium">{step.label}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
