import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Event, DTO } from '../types';

const { REACT_APP_REST_API } = process.env;

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_REST_API,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('access-token') as string;
      headers.set('authorization', `Bearer ${token}`);
    },
  }),
  tagTypes: ['Event'],
  endpoints: (builder) => ({
    getEvents: builder.query<Event[], { month: number; year: number }>({
      query: (arg) => ({
        url: '/v1/timeaway',
        method: 'post',
        body: {
          data_request: {
            period: 'PERIOD_MONTH',
            params: {
              month: arg.month + 1,
              year: arg.year,
            },
            timezone: 'local',
          },
          filter: 'FILTER_OFFICE',
          id: ['1', '2', '3'],
        },
      }),
      transformResponse: ({ timeaway }) =>
        timeaway.map((item: any) => ({
          userFullName: item.name,
          userFirstName: item?.userFirstName,
          userLastName: 'Last name',
          userPictureURL: item?.userPictureURL || '',
          type: item?.type || 'Public holiday',
          colorName: 'red',
          start: item?.start,
          startTimeOfDay: item?.startTimeOfDay || 'end of the day',
          end: item?.end,
          endTimeOfDay: item?.endTimeOfDay || 'midday',
          description: item?.title,
          title: `${item?.name} ${item?.type}`,
          id: item?.pk,
        })),
      providesTags: (result = []) => [
        'Event',
        ...result.map(({ id }) => ({ type: 'Event' as const, id })),
      ],
    }),
    getEvent: builder.query({
      query: (id) => ({
        url: '/event',
        method: 'post',
        body: {},
      }),
      providesTags: (result, error, id) => [{ type: 'Event', id }],
    }),
    createEvent: builder.mutation<Event, DTO.Events.Create.REQ>({
      query: (body) => ({
        url: '/',
        method: 'post',
        body,
      }),
      transformResponse: ({ event }: DTO.Events.Create.RES) => event,
      invalidatesTags: ['Event'],
    }),
  }),
});

export const { useGetEventsQuery, useCreateEventMutation } = eventApi;
