/* eslint-disable import/no-anonymous-default-export */
import { PaperClipIcon } from '@heroicons/react/20/solid';

export default ({ payload }: any) => {
  const constructPayload = (item: any) => {
    const payload = (
      <>
        {item.fields.map(
          (item: {
            id: string;
            name: string;
            value: any;
            image: string;
            mapper?: (items: any[]) => any[];
          }) => (
            <div className="sm:col-span-1" key={item.name}>
              {item.image && (
                <dl>
                  <dt className="text-sm font-medium text-gray-500">
                    {item.name}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <a
                      href={`/profile/${item.id}`}
                      className="p-1"
                      title={item.name}
                    >
                      <img
                        alt=""
                        src={item.image}
                        className="h-11 w-11 flex-none rounded-full bg-gray-100"
                      />
                    </a>
                  </dd>
                </dl>
              )}

              {!item.image && !item.mapper && (
                <dl>
                  <dt className="text-sm font-medium text-gray-500">
                    {item.name}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{item.value}</dd>
                </dl>
              )}

              {item.mapper && (
                <dl>
                  <dt className="text-sm font-medium text-gray-500">
                    {item.name}
                  </dt>
                  <dd className="mt-4 flex flex-row text-sm text-gray-900">
                    {item?.value?.map((user: any) => {
                      const component = (
                        <a
                          key={user.id}
                          href={`/profile/${user.id}`}
                          className="p-1"
                          title={user.full_name}
                        >
                          <img
                            alt=""
                            src={user.safe_profile_picture_url}
                            className="h-11 w-11 flex-none rounded-full bg-gray-100"
                          />
                        </a>
                      );
                      return component;
                    })}
                  </dd>
                </dl>
              )}
            </div>
          ),
        )}
      </>
    );
    return payload;
  };

  return (
    <div className="mb-5 overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {payload?.title}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {payload?.subtitle}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {constructPayload(payload)}

          {payload.attachments && (
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        resume_back_end_developer.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href="#"
                        className="font-medium text-red-600 hover:text-red-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <PaperClipIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        coverletter_back_end_developer.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href="#"
                        className="font-medium text-red-600 hover:text-red-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};
