import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@tremor/react';
import { Pill } from 'evergreen-ui';
import { FindCandidates } from './components/FindCandidates';
import { ResumeManagment } from './components/ResumeManagment/ResumeManagment';
import { YourVacancies } from './components/YourVacancies';
import { Dashboard } from './components/Dashboard/Dashboard';
import { StagesOfWork } from './components/StagesOfWork';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarRoute } from './components/Calendar';

const jobTabs = [
  {
    name: 'Vacancies',
    value: 1,
  },
  {
    name: 'Find Candidate',
    value: 2,
    candidates: 1,
  },
  {
    name: 'Resume management',
    value: 3,
  },
  {
    name: 'Calendar',
    value: 5,
  },
  {
    name: 'Dashboard',
    value: 6,
  },
];

export const JobRoute = () => {
  return (
    <div>
      <TabGroup defaultIndex={0}>
        <TabList variant="line" color="red-500" className="mb-6">
          {jobTabs.map((tab) => {
            return (
              <Tab key={tab.value} value={tab.value}>
                {tab.name}
                {tab?.candidates && (
                  <Pill
                    className="text-xs font-medium"
                    marginLeft={4}
                    marginBottom={2}
                    color="green"
                  >
                    {tab.candidates}
                  </Pill>
                )}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          <TabPanel>
            <YourVacancies />
          </TabPanel>
          <TabPanel>
            <FindCandidates />
          </TabPanel>

          <TabPanel>
            <ResumeManagment />
          </TabPanel>
          <TabPanel>
            <div className="h-[95vh]">
              <CalendarRoute />
            </div>
          </TabPanel>
          <TabPanel>
            <Dashboard />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};
