import { Fragment, useEffect, useState } from 'react';
import { Moment } from 'moment';
import classnames from 'classnames';

import { Listbox, Menu, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';

import {
  CALENDAR_MODE__MONTH,
  CALENDAR_MODE__WEEK,
  CALENDAR_MODE__DAY,
} from '../../constants';
import { EventDialog } from './EventDialog';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Spinner } from '../../ui/Spinner';
import { Switch } from '../../ui/Switch';

interface IProps {
  today: Moment;
  mode: string;
  setMode(mode: string): void;
  onPrevious(): void;
  onToday(): void;
  onNext(): void;
  setPerson(person: any): void;
  setOffice(office: any): void;
  setIsMyEvents(checked: boolean): void;
}

type Location = {
  id: string;
  name: string;
};

export function CalendarHeader({
  today,
  mode,
  setMode,
  onPrevious,
  onToday,
  onNext,
  setPerson,
  setOffice,
  setIsMyEvents,
}: IProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const [loading, setLoading] = useState(false);

  const { data: people = [], refetch } = useQuery({
    queryKey: ['people', { office: currentLocation?.id || 0 }],
    queryFn: async ({ queryKey }) => {
      setLoading(true);
      const { data = [] } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/v1/people`,
        params: {
          office: currentLocation?.id || 0,
          page: 0,
          limit: 200,
        },
      });
      setLoading(false);
      setSelected(0);
      return data;
    },
    staleTime: 300000,
    cacheTime: 900000,
  });

  const { data: locations = [] } = useQuery({
    queryKey: ['locations'],
    queryFn: async (options) => {
      const { data = [] } = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/v1/office`,
      });
      return data;
    },
  });

  const [selected, setSelected] = useState<any>(0);

  useEffect(() => {
    setPerson(selected);
    setOffice(currentLocation);
  }, [selected, currentLocation]);

  useEffect(() => {
    if (currentLocation?.id !== '0') {
      refetch();
    }
  }, [currentLocation, refetch]);

  return (
    <header className="flex items-center space-x-3 border-b border-gray-200 px-6 py-4 lg:flex-none">
      <h1 className="text-lg font-semibold text-gray-900">
        <time dateTime={today.format('YYYY-MM')} className="sm:hidden">
          {today.format('MMM YYYY')}
        </time>
        <time dateTime={today.format('YYYY-MM')} className="hidden sm:inline">
          {today.format('MMMM YYYY')}
        </time>
      </h1>
      <div className="flex items-center gap-3">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          <button
            onClick={onPrevious}
            type="button"
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={onToday}
            type="button"
            className="hidden border-b border-t border-gray-300 bg-white px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
          >
            Today
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />

          <button
            onClick={onNext}
            type="button"
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div>
          <Listbox value={currentLocation} onChange={setCurrentLocation}>
            {({ open }) => (
              <>
                <div className="relative px-4">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {currentLocation?.name || 'All locations'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        key={0}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                          )
                        }
                        value={0}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              )}
                            >
                              All locations
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {locations.data &&
                        locations.data.map((person: any) => (
                          <Listbox.Option
                            key={person.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-indigo-600 text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-8 pr-4',
                              )
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate',
                                  )}
                                >
                                  {person.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div>
          <div>
            {loading ? (
              <Listbox>
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                  <span className="flex items-center">
                    <Spinner size={6} />
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
              </Listbox> // Display a loading message or spinner
            ) : (
              people?.data && (
                <Listbox value={selected} onChange={setSelected}>
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            {selected === 0 ? (
                              <>
                                <span className="ml-3 block truncate">
                                  All employees
                                </span>
                              </>
                            ) : (
                              <>
                                {selected?.safe_profile_picture_url && (
                                  <img
                                    src={
                                      selected?.safe_profile_picture_url || ''
                                    }
                                    alt=""
                                    className="h-5 w-5 flex-shrink-0 rounded-full"
                                  />
                                )}
                                <span className="ml-3 block truncate">
                                  {selected?.full_name}
                                </span>
                              </>
                            )}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <Listbox.Option
                              key={0}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-indigo-600 text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9',
                                )
                              }
                              value={0}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'ml-3 block truncate',
                                      )}
                                    >
                                      All employees
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            {people.data.map((person: any) => (
                              <Listbox.Option
                                key={person.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? 'bg-indigo-600 text-white'
                                      : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                  )
                                }
                                value={person}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <img
                                        src={person.safe_profile_picture_url}
                                        alt=""
                                        className="h-5 w-5 flex-shrink-0 rounded-full"
                                      />
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'ml-3 block truncate',
                                        )}
                                      >
                                        {person.full_name}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? 'text-white'
                                            : 'text-indigo-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4',
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              )
            )}
          </div>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" className="relative hidden">
            <Menu.Button
              type="button"
              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium capitalize text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {mode}
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>

            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {[
                  CALENDAR_MODE__MONTH,
                  CALENDAR_MODE__WEEK,
                  CALENDAR_MODE__DAY,
                ].map((mode) => (
                  <Menu.Item key={mode}>
                    {({ active }) => (
                      <div
                        onClick={() => setMode(mode)}
                        className={classnames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block cursor-pointer px-4 py-2 text-sm',
                        )}
                      >
                        {mode} view
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setIsDialogOpen(true)}
                      className={classnames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block cursor-pointer px-4 py-2 text-sm',
                      )}
                    >
                      Create event
                    </div>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={onToday}
                      className={classnames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block cursor-pointer px-4 py-2 text-sm',
                      )}
                    >
                      Go to today
                    </div>
                  )}
                </Menu.Item>
              </div>
              <div className="hidden py-1">
                {[
                  CALENDAR_MODE__MONTH,
                  CALENDAR_MODE__WEEK,
                  CALENDAR_MODE__DAY,
                ].map((mode) => (
                  <Menu.Item key={mode}>
                    {({ active }) => (
                      <div
                        onClick={() => setMode(mode)}
                        className={classnames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block cursor-pointer px-4 py-2 text-sm',
                        )}
                      >
                        {mode} view
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <Switch onChange={setIsMyEvents} label="My events" />
      </div>
      <EventDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </header>
  );
}
