export enum ESettingView {
  AccountView = 'ACCOUNT_VIEW',
  NotificationsView = 'NOTIFICATIONS_VIEW',
  SecurityView = 'SECURITY_VIEW',
  AppearanceView = 'APPEARANCE_VIEW',
  BillingView = 'BILLING_VIEW',
  IntegrationsView = 'INTEGRATIONS_VIEW',
}

export enum OnboardingSteps {
  FirstStep,
  SecondStep,
  FinalStep,
}
