import { Moment } from 'moment';
import classnames from 'classnames';

interface IProps {
  today: Moment;
  selectedDay: Moment;
  setSelectedDay: (day: Moment) => void;
}

export function MobileDayCalendarView({
  today,
  selectedDay,
  setSelectedDay,
}: IProps) {
  const startDay = today.clone().startOf('day');
  const day = startDay.clone().subtract(startDay.isoWeekday(), 'day');
  const daysMap = [...Array(7)].map(() => day.add(1, 'day').clone());

  return (
    <div className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden">
      {daysMap.map((day, index) => (
        <button
          key={index}
          onClick={() => setSelectedDay(day)}
          type="button"
          className="flex flex-col items-center pb-1.5 pt-3"
        >
          <span>{day.format('dddd').substring(0, 1)}</span>
          <span
            className={classnames(
              day.isSame(selectedDay, 'day')
                ? 'bg-gray-900 text-white'
                : day.isSame(today, 'day') && 'text-red-600',
              'mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold',
            )}
          >
            {day.format('D')}
          </span>
        </button>
      ))}
    </div>
  );
}
