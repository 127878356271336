import React from 'react';

export const Switch = ({
  label,
  onChange,
  value,
}: {
  label?: string;
  onChange: (checked: boolean) => void;
  value?: boolean;
}) => {
  return (
    <label className="inline-flex cursor-pointer items-center">
      <input
        type="checkbox"
        className="peer sr-only"
        onChange={(e) => onChange(e.target.checked)}
        checked={value || false}
      />
      <div className="peer relative h-6 w-11 rounded-full bg-[#F2F4F7] after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#475467] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full"></div>
      {label && (
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </span>
      )}
    </label>
  );
};
